import { CreateParams, GetListParams, GetOneParams, DeleteParams, UpdateParams, GetManyParams } from 'react-admin'
import { storageGet, storageRemove, storageSet } from '@/utils/storage'
import { EQueryParam, TMeta, ERole, ERoleRequest } from '@/types/data'
import { axiosAuthInstance } from './axios'
import { DASHBOARD } from './routes'
import { isArray, omit } from 'underscore'

import { TOKEN_KEY, REFRESH_TOKEN_KEY, SESSION_KEY, PERMISSIONS_KEY } from './consts'
import { EResource } from '@/admin/types'

export const setAccessToken = (token: string) => storageSet(TOKEN_KEY, token)
export const getAccessToken = () => storageGet(TOKEN_KEY)
export const removeAccessToken = () => storageRemove(TOKEN_KEY)
export const setRefreshToken = (token: string) => storageSet(REFRESH_TOKEN_KEY, token)
export const getRefreshToken = () => storageGet(REFRESH_TOKEN_KEY)
export const removeRefreshToken = () => storageRemove(REFRESH_TOKEN_KEY)
export const setSession = (session: { username: string; id: string }) =>
  storageSet(SESSION_KEY, JSON.stringify(session))
export const getSession = () => storageGet(SESSION_KEY)
export const removeSession = () => storageRemove(SESSION_KEY)
export const setPermissions = (role: string) => storageSet(PERMISSIONS_KEY, role)
export const getPermissions = () => storageGet(PERMISSIONS_KEY)
export const removePermissions = () => storageRemove(PERMISSIONS_KEY)

const trimmer = (_key: string, value: any) => {
  if (typeof value === 'string') {
    return value.trim()
  }
  return value
}

export const paramsMapper = ({ filter, pagination, sort }: GetListParams) => {
  const { page, perPage } = pagination
  const { field, order } = sort

  const sortBy = field

  return {
    [EQueryParam.Offset]: (page - 1) * perPage,
    [EQueryParam.Limit]: perPage,
    [EQueryParam.SortBy]: sortBy,
    [EQueryParam.SortingDirection]: order.toLowerCase(),
    ...filter,
  }
}

export const responseMapper = (response: [] | { data: []; meta: TMeta }) => {
  const data = Array.isArray(response) ? response : response.data
  const total = !Array.isArray(response) ? response.meta.totalCount : response.length

  return { data, total }
}

export const getCustomList = async (resource: string, params: GetListParams) => {
  const { data } = await axiosAuthInstance.get(`${DASHBOARD()}/${resource}`, { params: paramsMapper(params) })

  return responseMapper(data)
}

export const getCustomOne = async (resource: string, params: GetOneParams) => {
  const { id } = params
  const { data } = await axiosAuthInstance.get(`${DASHBOARD()}/${resource}/${id}`)

  return { data }
}

export const getCustomMany = async (resource: string, params: GetManyParams) => {
  const { data } = await axiosAuthInstance.get(`${DASHBOARD()}/${resource}`, { params })

  return responseMapper(data)
}

export const customCreate = async (resource: string, params: CreateParams) => {
  const resData: {
    data: any
  } = {
    data: {
      data: {},
    },
  }

  const trimmedData = JSON.parse(JSON.stringify(params.data, trimmer))
  let finalData: any = {}
  if (resource === EResource.Admins) {
    Object.keys(trimmedData).forEach((key) => {
      const value = trimmedData[key]
      if (value !== '') {
        finalData[key] = trimmedData[key]
      }
    })
  } else {
    finalData = trimmedData
  }

  try {
    const { data } = await axiosAuthInstance.post(`${DASHBOARD()}/${resource}`, finalData)
    resData.data = {
      data,
      id: data.createdId,
    }
  } catch (error: any) {
    const { message } = error.response.data
    throw new Error(`Error: ${message}`)
  }

  return resData
}

export const customDelete = async (resource: string, params: DeleteParams) => {
  const { id } = params
  const resData: {
    data: any
  } = {
    data: {
      data: {},
      id,
    },
  }

  try {
    const { data } = await axiosAuthInstance.delete(`${DASHBOARD()}/${resource}/${id}`)
    resData.data = {
      data,
      id,
    }
  } catch (error: any) {
    const { message } = error.response.data
    throw new Error(`Error: ${message}`)
  }

  return resData
}

export const adminsUpdate = async (resource: string, params: UpdateParams) => {
  const { id, previousData, data } = params
  const resData: {
    data: any
  } = {
    data: {
      data: {},
      id,
    },
  }

  const updatedValues = omit(data, (v, k) => {
    if (![ERole.Supplier, ERoleRequest.Supplier].includes(data.role) && isArray(v)) {
      return true
    }
    return previousData[k] === v
  })

  try {
    const { data } = await axiosAuthInstance.put(
      `${DASHBOARD()}/${resource}/${id}`,
      JSON.parse(JSON.stringify(updatedValues, trimmer)),
    )
    resData.data = {
      data,
      id,
    }
  } catch (error: any) {
    const { message } = error.response.data
    throw new Error(`Error: ${message}`)
  }

  return resData
}

export const customUpdate = async (resource: string, params: UpdateParams) => {
  const { id } = params
  const resData: {
    data: any
  } = {
    data: {
      data: {},
      id,
    },
  }

  try {
    const { data } = await axiosAuthInstance.put(
      `${DASHBOARD()}/${resource}/${id}`,
      JSON.parse(JSON.stringify(params.data, trimmer)),
    )
    resData.data = {
      data,
      id,
    }
  } catch (error: any) {
    const { message } = error.response.data
    throw new Error(`Error: ${message}`)
  }

  return resData
}

export const dateTimeZoneFormatters = {
  parse: (value: string) => `${value}+03:00`,
  format: (value: string) => {
    const formatted = value?.replace(/\+03:00/g, '')
    return formatted
  },
}
