import { axiosInstanceRaw } from '../axios'
import { REFRESH_TOKEN_URL, DASHBOARD } from '../routes'

export const getNewAccessToken = async (refreshToken: string) => {
  try {
    const { data } = await axiosInstanceRaw.post<{
      accessToken: string
    }>(DASHBOARD() + REFRESH_TOKEN_URL(), {
      refreshToken,
    })

    return data
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
