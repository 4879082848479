import { TSettings } from '@/types/data'
import { axiosAuthInstance } from '../axios'

export const apiSettingsFetch = async () => {
  try {
    const { data } = await axiosAuthInstance.get<TSettings>('/dashboard/settings')

    return data
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const apiSettingsChange = async (settings: TSettings) => {
  try {
    const { data } = await axiosAuthInstance.put<TSettings>('/dashboard/settings', settings)

    return data
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
