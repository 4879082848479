import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BulkExportButton,
  SearchInput,
  WithRecord,
  Button,
  useRedirect,
  Identifier,
  BooleanInput,
  useUpdate,
  SimpleForm,
  WrapperField,
  useRefresh,
  useNotify,
  useRecordContext,
  LoadingIndicator,
} from 'react-admin'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'

import { EUser } from '@/modules/Users/types'
import { tokens } from '@/Layout/theme'
import { ORDERS } from '@/services/api/routes'

const UsersBulkActionButtons = () => {
  return (
    <>
      <BulkExportButton />
    </>
  )
}

const usersFilters = [
  <SearchInput source={EUser.NameLike} alwaysOn key={1} placeholder="Search (name)" />,
  <SearchInput source={EUser.Phone} alwaysOn key={2} placeholder="Search (phone)" />,
]

const BlockUserButton = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const colors = tokens()

  const [update, { isLoading }] = useUpdate()

  const toggleBlocking = () => {
    update(
      'users',
      { id: record.id, data: { ...record, isBlocked: !record.isBlocked }, previousData: record },
      {
        mutationMode: 'optimistic',
        onSuccess: () => {
          notify(!record.isBlocked ? 'User Blocked' : 'User unblocked', {
            type: !record.isBlocked ? 'warning' : 'success',
          })
          refresh()
        },
        onError: (error) => {
          notify(`Error: ${error}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <SimpleForm
      toolbar={false}
      sx={{ display: 'flex', padding: '12px 0', paddingBottom: '0 !important', minWidth: '150px' }}
    >
      {isLoading ? (
        <LoadingIndicator sx={{ padding: '18px 6px 20px' }} />
      ) : (
        <BooleanInput
          source={EUser.IsBlocked}
          label={record.isBlocked ? 'Unblock user' : 'Block user'}
          onChange={toggleBlocking}
          sx={{ '& .MuiSwitch-thumb': { backgroundColor: record.isBlocked ? colors.accent.pink : 'white' } }}
        />
      )}
    </SimpleForm>
  )
}

export const UsersList = () => {
  const colors = tokens()
  const redirect = useRedirect()

  const handleRedirect =
    ({ id }: { id: Identifier }) =>
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      redirect(
        'list',
        encodeURI(
          `${ORDERS()}?displayedFilters={"userIdLike":true}&filter={"userIdLike":"${id}"}&order=ASC&page=1&perPage=25&sort=id`,
        ),
      )
    }

  return (
    <List filters={usersFilters} actions={false}>
      <Datagrid rowClick={false} bulkActionButtons={<UsersBulkActionButtons />}>
        <TextField source={EUser.Id} />
        <TextField source={EUser.Name} sortable={false} />
        <TextField source={EUser.Phone} sortable={false} />
        <DateField source={EUser.CreatedAt} sortable={false} />

        <WithRecord
          label="Orders Count"
          render={(record) => (
            <NumberField
              source={EUser.OrdersCount}
              sortable={false}
              textAlign="left"
              sx={{
                color: record[EUser.OrdersCount] === 0 ? colors.redAccent[500] : colors.neutral[900],
              }}
            />
          )}
        />

        <WrapperField label="Is blocked">
          <BlockUserButton />
        </WrapperField>

        <WithRecord
          render={(record) => (
            <Button
              onClick={handleRedirect(record)}
              endIcon={<ShoppingCartOutlinedIcon />}
              variant="outlined"
              disabled={!record[EUser.OrdersCount]}
            >
              <h6
                style={{
                  margin: '0',
                  fontSize: '12px',
                  borderRight: `1px solid ${colors.cyanosed[400]}`,
                  paddingRight: '8px',
                }}
              >
                User Orders
              </h6>
            </Button>
          )}
        />
      </Datagrid>
    </List>
  )
}
