import { ReactNode } from 'react'
import ProgressCircle from './ProgressCircle'
import { Box, Typography } from '@mui/material'
import { tokens } from '@/Layout/theme'

type TProps = {
  title: string
  subtitle: string
  icon: ReactNode
  progress: number
  increase: string
  progressColors?: {
    inner: string
    background: string
    main: string
  }
}

const StatBox = ({
  title,
  subtitle,
  icon,
  progress,
  increase,
  progressColors,
}: TProps) => {
  const colors = tokens()

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.neutral[0] }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <ProgressCircle progress={progress} size="40" {...progressColors} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography
          variant="h6"
          sx={{ color: colors.primary[300], fontSize: '16px' }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="h6"
          fontStyle="italic"
          sx={{ color: colors.primary[300], fontSize: '16px' }}
        >
          {increase}
        </Typography>
      </Box>
    </Box>
  )
}

export default StatBox
