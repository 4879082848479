import { ReactNode } from 'react'
import { Layout } from 'react-admin'
import { Typography } from '@mui/material'

import { tokens } from '@/Layout/theme'
import { NavBar } from './NavBar'
import { NavMenu } from './NavMenu'

export const AdminLayout = (props: { children?: ReactNode }) => {
  const colors = tokens()

  return (
    <Layout
      {...props}
      appBar={NavBar}
      menu={NavMenu}
      sx={{
        background: colors.neutral[0],
        '& .RaLayout-content': {
          background: colors.cyanosed[100],
          padding: '0 32px 0 24px',
        },
        '& .RaNotFound-message': {
          color: colors.neutral[800],
        },
        '& .MuiButton-textPrimary': {
          color: colors.primary[700],
          fontWeight: 600,
          fontSize: '12px',
        },
        '& .MuiButton-containedPrimary': {
          backgroundColor: colors.primary[700],

          '&:hover': {
            backgroundColor: colors.primary[600],
          },
        },
        '& .RaDatagrid-headerCell > span': {
          whiteSpace: 'nowrap',
        },
      }}
    >
      <Typography
        variant="h4"
        id="react-admin-title"
        sx={{
          marginTop: '30px',
          marginBottom: '10px',
          color: colors.neutral[600],
          fontWeight: '600',
          fontSize: '20px',
        }}
      />
      {props.children}
    </Layout>
  )
}
