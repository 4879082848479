import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  WrapperField,
  DateField,
  ArrayField,
  Datagrid,
  BooleanField,
  Button,
  WithRecord,
  useNotify,
  Identifier,
} from 'react-admin'

import { postResendSms } from '@/services/api/methods/postResendSMS'
import { Box } from '@mui/material'
import { EResource } from '@/admin/types'

import { tokens } from '@/Layout/theme'
import SMSIcon from '@mui/icons-material/SmsOutlined'

import { EMatch, ETeam } from '@/modules/Matches/types'
import { LabelField } from '@/components/LabelField'

import { ETicket } from '@/modules/Tickets/types'
import { ETicketOrder } from '@/modules/TicketOrders/types'

export const TicketOrderShow = () => {
  const colors = tokens()
  const notify = useNotify()

  const handleResendSms =
    ({ id }: { id: Identifier }) =>
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      postResendSms(id, true, notify)
    }

  return (
    <Show resource={EResource.TicketOrders}>
      <SimpleShowLayout>
        <WithRecord
          render={(record) => (
            <Button onClick={handleResendSms(record)} endIcon={<SMSIcon />} sx={{ margin: '10px' }} fullWidth>
              <h6
                style={{
                  margin: '0',
                  fontSize: '12px',
                  borderRight: `1px solid ${colors.cyanosed[400]}`,
                  paddingRight: '6px',
                }}
              >
                Resend SMS
              </h6>
            </Button>
          )}
        />

        <NumberField source={ETicketOrder.Id} textAlign="left" sortable={false} />
        <TextField source={ETicketOrder.OrderNumber} sortable={false} label="Order Number" />
        <NumberField source={ETicketOrder.TotalPrice} textAlign="left" sortable={false} />
        <LabelField source={ETicketOrder.Status} label="Status" sortable={false} />
        <TextField source={ETicketOrder.TransactionId} sortable={false} label="Transaction Id" />

        <ReferenceField
          source={`${ETicketOrder.Match}.${EMatch.Id}`}
          reference="matches"
          sortable={false}
          label="Match Id"
        >
          <TextField source={EMatch.Id} />
        </ReferenceField>

        <Box display="flex">
          <WrapperField label="Match - Team A">
            <Box
              display="flex"
              flexDirection="column"
              margin="4px"
              border="1px solid red"
              padding="6px"
              minWidth="150px"
            >
              <b>Team A</b>
              <Box marginBottom="12px">
                <p>
                  <b>Arab Name</b>
                </p>
                <TextField source={`${ETicketOrder.Match}.${EMatch.TeamA}.${ETeam.ArName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Ku Name</b>
                </p>
                <TextField source={`${ETicketOrder.Match}.${EMatch.TeamA}.${ETeam.KuName}`} sortable={false} />
              </Box>
            </Box>
          </WrapperField>
          <WrapperField label="Match - Team B">
            <Box
              display="flex"
              flexDirection="column"
              margin="4px"
              border="1px solid blue"
              padding="6px"
              minWidth="150px"
            >
              <b>Team B</b>
              <Box marginBottom="12px">
                <p>
                  <b>Arab Name</b>
                </p>
                <TextField source={`${ETicketOrder.Match}.${EMatch.TeamB}.${ETeam.ArName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Ku Name</b>
                </p>
                <TextField source={`${ETicketOrder.Match}.${EMatch.TeamB}.${ETeam.KuName}`} sortable={false} />
              </Box>
            </Box>
          </WrapperField>
          <WrapperField label="Match - Stadium">
            <Box
              display="flex"
              flexDirection="column"
              margin="4px"
              border="1px solid black"
              padding="6px"
              minWidth="150px"
            >
              <b>Stadium</b>
              <Box marginBottom="12px">
                <p>
                  <b>Arab Name</b>
                </p>
                <TextField source={`${ETicketOrder.Match}.${EMatch.Stadium}.${ETeam.ArName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Ku Name</b>
                </p>
                <TextField source={`${ETicketOrder.Match}.${EMatch.Stadium}.${ETeam.KuName}`} sortable={false} />
              </Box>
            </Box>
          </WrapperField>
        </Box>

        <DateField source={ETicketOrder.CreatedAt} sortable={false} />
        <DateField source={ETicketOrder.UpdatedAt} sortable={false} />

        <TextField source={`${ETicketOrder.User}.name`} sortable={false} label="User Name" />
        <TextField source={`${ETicketOrder.User}.phone`} sortable={false} label="User Phone" />
        <NumberField
          source={`${ETicketOrder.User}.ordersCount`}
          textAlign="left"
          sortable={false}
          label="Orders Count"
        />

        <ArrayField source={ETicketOrder.Tickets}>
          <Datagrid rowClick={false} bulkActionButtons={false}>
            <ReferenceField source={ETicket.Id} reference="tickets" sortable={false} label="Ticket Id">
              <TextField source={ETicket.Id} sortable={false} />
            </ReferenceField>

            <TextField source={ETicket.HolderName} sortable={false} />
            <TextField source={ETicket.HolderPhone} sortable={false} />
            <DateField source={ETicket.CreatedAt} sortable={false} />
            <NumberField source={ETicket.Price} sortable={false} textAlign="left" />
            <TextField source={ETicket.UserId} sortable={false} label="User Id" />
            <TextField source={ETicket.Sector} sortable={false} />
            <DateField source={ETicket.UpdatedAt} sortable={false} />
            <BooleanField source={ETicket.Used} sortable={false} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}
