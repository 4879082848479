import { TProduct } from '../../Products/types'
import { TPromocode } from '../../Promocodes/types'

export enum EOrder {
  Id = 'id',
  Status = 'status',
  PromoCode = 'promoCode',
  TransactionId = 'transactionId',
  CreatedAt = 'createdAt',
  TotalPrice = 'totalPrice',
  Product = 'product',
  Fees = 'fees',
  ToppedUpUser = 'toppedUpUser',
  MerchantUserId = 'merchantUserId',
  Phone = 'phone',
  CreatedAtAfter = 'createdAtAfter',
  CreatedAtBefore = 'createdAtBefore',
}

export enum EStatus {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export enum EConsumer {
  Id = 'id',
  Phone = 'phone',
}

export type TOrder = {
  [EOrder.Id]: number
  [EOrder.PromoCode]: TPromocode
  [EOrder.Status]: EStatus
  [EOrder.TransactionId]: string
  [EOrder.CreatedAt]: Date
  [EOrder.TotalPrice]: number
  [EOrder.Product]: TProduct
  [EOrder.Fees]: number
  [EOrder.ToppedUpUser]: string
  [EOrder.MerchantUserId]: string
}
