import { EType, EProvider } from './types'

export const typeChoices = [
  {
    id: 1,
    name: EType.BUNDLE,
  },
  {
    id: 2,
    name: EType.VOUCHER,
  },
]

export const providerChoices = [
  {
    id: 1,
    name: EProvider.ASIACELL,
  },
  {
    id: 2,
    name: EProvider.MINTROUTE,
  },
  {
    id: 3,
    name: EProvider.DEFAULT,
  },
]
