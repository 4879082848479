import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  WithRecord,
  FormDataConsumer,
} from 'react-admin'
import { EAdmin } from './types'
import { EBrand } from '../Brands/types'
import { adminsEditChoices } from './consts'
import { ERole, ERoleRequest } from '@/types/data'
import { requiredTrimmed } from '@/utils/validation'

export const AdminsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="list">
    <SimpleForm sanitizeEmptyValues>
      <FormDataConsumer>
        {({ formData }) => {
          switch (formData.role) {
            case ERole.Admin:
            case ERoleRequest.Admin:
            case ERole.Superadmin:
            case ERoleRequest.Superadmin:
            case ERoleRequest.TZadmin:
              return (
                <SelectInput
                  source={EAdmin.Role}
                  choices={adminsEditChoices}
                  validate={required()}
                  optionValue="name"
                  fullWidth
                />
              )
            case ERole.Merchant:
            case ERoleRequest.Merchant:
              return (
                <>
                  <TextInput
                    source={EAdmin.MerchantId}
                    label="Merchant Id"
                    fullWidth
                    validate={requiredTrimmed}
                    required
                  />
                </>
              )
            case ERole.Supplier:
            case ERoleRequest.Supplier:
              return (
                <WithRecord
                  render={(record) => (
                    <ReferenceArrayInput source={EAdmin.BrandIds} reference="brands" perPage={100}>
                      <AutocompleteArrayInput
                        optionText={EBrand.EnName}
                        fullWidth
                        defaultValue={record[EAdmin.BrandIds]}
                        validate={required()}
                      />
                    </ReferenceArrayInput>
                  )}
                />
              )
            default:
              return <div />
          }
        }}
      </FormDataConsumer>

      <BooleanInput source={EAdmin.IsActive} fullWidth />
    </SimpleForm>
  </Edit>
)
