import { TAdmin } from "../Admins/types"
import { TVariant } from "../Variants/types"

export enum ECostChange {
  Id = 'id',
  VariantId = 'variantId',
  Variant = 'variant',
  Cost = 'cost',
  DateOfChange = 'dateOfChange',
  CreatedAt = 'createdAt',
  CreatedBy = 'admin',
  Version = 'version',
}

export type TCostChange = {
  [ECostChange.Id]: number
  [ECostChange.Variant]: TVariant
  [ECostChange.Cost]: number
  [ECostChange.DateOfChange]: Date
  [ECostChange.CreatedAt]: Date
  [ECostChange.CreatedBy]: TAdmin
  [ECostChange.Version]: number
}
