import {
  List,
  Datagrid,
  TextField,
  NumberField,
  WrapperField,
  DateField,
  ChipField,
  required,
  SelectInput,
  BulkExportButton,
  TopToolbar,
  FilterButton,
  TextInput,
  NumberInput,
  ReferenceInput,
  WithRecord,
} from 'react-admin'
import { Box } from '@mui/material'
import { tokens } from '@/Layout/theme'

import { EOrder, EStatus, EConsumer } from '@/modules/Orders/types'
import { LabelField } from '@/components/LabelField'
import { EPromocode } from '@/modules/Promocodes/types'
import { EProduct } from '@/modules/Products/types'

export const choices = [
  {
    id: 1,
    name: EStatus.SUCCESS,
  },
  {
    id: 2,
    name: EStatus.PENDING,
  },
  {
    id: 3,
    name: EStatus.CANCELLED,
  },
  {
    id: 4,
    name: EStatus.FAILURE,
  },
]
const ordersFilters = [
  <NumberInput source={EOrder.Id} alwaysOn key={1} label="Search (id)" />,
  <TextInput source={EOrder.UserPhoneNumberLike} alwaysOn key={2} label="Search (User phone)" />,
  <NumberInput source={EOrder.TransactionIdLike} alwaysOn key={3} label="Search (Transaction id)" />,
  <TextInput source={EOrder.MerchantUserId} key={4} label="Search (Merchant id)" />,
  <SelectInput
    label="Status Filter"
    source={EOrder.Status}
    defaultValue={EStatus.SUCCESS}
    key={EStatus.SUCCESS}
    choices={choices}
    validate={required()}
    optionValue="name"
  />,
  <TextInput source={EOrder.UserIdLike} key={5} label="User Id" />,

  <ReferenceInput source={EOrder.BrandId} reference={'brands'} perPage={1000} key={6}>
    <SelectInput optionText="enName" />
  </ReferenceInput>,

  <ReferenceInput source={EOrder.ProductId} reference={'products'} perPage={1000} key={7}>
    <SelectInput optionText="enName" />
  </ReferenceInput>,

  <ReferenceInput source={EOrder.VariantId} reference={'variants'} perPage={1000} key={8}>
    <SelectInput optionText="value[0][enValue]" />
  </ReferenceInput>,
]

const PostOrdersActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
)

const OrdersBulkActionButtons = () => {
  return (
    <>
      <BulkExportButton />
    </>
  )
}

export const OrdersList = () => {
  const colors = tokens()
  return (
    <List filters={ordersFilters} sort={{ field: EOrder.CreatedAt, order: 'DESC' }} actions={<PostOrdersActions />}>
      <Datagrid rowClick="show" bulkActionButtons={<OrdersBulkActionButtons />}>
       
        <DateField source={EOrder.CreatedAt} showTime />
        <WrapperField label="Consumer">
          <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
            <TextField source={`${EOrder.Consumer}.${EConsumer.Id}`} sortable={false} />
            <TextField source={`${EOrder.Consumer}.${EConsumer.Phone}`} sortable={false} />
          </Box>
        </WrapperField>
        <NumberField source={EOrder.Id} label="ID" textAlign="left" sortable={false} />

        <TextField source={EOrder.TotalPrice} sortable={false} />
        <TextField source={`${EOrder.PromoCode}${EPromocode.Value}`} label="Promocode" sortable={false} />
        <TextField source={EOrder.TransactionId} sortable={false} label="Transaction Id" />

        <LabelField source={EOrder.Status} label="Status" sortable={false} />
       
        
        <WithRecord
          label="Payment Method"
          render={(record) => {
            return (
              <>
                <TextField source={EOrder.PaymentMethod} />{' '}
                <span> - {record['merchant'] ? record['merchant']['name'] : ''}</span>
              </>
            )
          }}
        />
       
       
        <ChipField
          sx={{
            backgroundColor: colors.accent.pink,
            color: colors.neutral[0],
          }}
          source={`${EOrder.Product}.${EProduct.EnName}`}
          label="Product"
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}
