import { useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ChipField,
  ArrayField,
  NumberField,
  WithRecord,
  WrapperField,
  BooleanField,
  DateField,
  SelectInput,
  required,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  SingleFieldList,
  Button,
  Confirm,
  BulkExportButton,
  BulkDeleteButton,
  TextInput,
  NumberInput,
} from 'react-admin'

import { Box } from '@mui/material'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import FileUploadIcon from '@mui/icons-material/UploadFile'
import { tokens } from '@/Layout/theme'
import { EVariant, EValue } from '@/modules/Variants/types'
import { EProvider, EType } from '@/modules/Products/types'
import { ImportCSVModal } from '@/components/ImportCSVModal'
import { LabelField } from '@/components/LabelField'

export const choices = [
  {
    id: 1,
    name: EType.BUNDLE,
  },
  {
    id: 2,
    name: EType.VOUCHER,
  },
]

const productsFilters = [
  <TextInput source={EVariant.BrandName} alwaysOn key={1} label="Search (Brand Name)" />,
  <NumberInput source={EVariant.ParentProductId} alwaysOn key={2} label="Search (Product Id)" />,
  <SelectInput
    label="Type"
    source={EVariant.ParentProductType}
    defaultValue={EType.BUNDLE}
    key={EVariant.ParentProductType}
    choices={choices}
    validate={required()}
    optionValue="name"
  />,
]

export const VariantsList = () => {
  const [open, setOpen] = useState(false)
  const colors = tokens()

  const handleCloseModal = () => setOpen(false)
  const handleConfirm = () => setOpen(false)

  const VariantsActions = () => (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      <Button onClick={() => setOpen(true)} label="Import vouchers from .csv">
        <FileUploadIcon />
      </Button>
    </TopToolbar>
  )

  return (
    <List filters={productsFilters} actions={<VariantsActions />}>
      <Confirm
        isOpen={open}
        title="Upload CSV"
        content={<ImportCSVModal handleClose={handleCloseModal} />}
        cancel=""
        confirm="Close"
        ConfirmIcon={() => <ErrorOutlinedIcon />}
        CancelIcon={() => null}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
      />
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          <>
            <BulkExportButton />
            <BulkDeleteButton />
          </>
        }
      >
        <NumberField source={EVariant.Id} textAlign="left" />
        <WithRecord
          label="Value"
          render={(record) => (
            <ArrayField source={EVariant.Value} sortable={false}>
              <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    sx={{
                      color: colors.accent.emerald,
                    }}
                    source={record.product.type === EType.BUNDLE ? EValue.EnName : EValue.EnValue}
                  />
                  <TextField
                    sx={{
                      color: colors.accent.pink,
                    }}
                    source={record.product.type === EType.BUNDLE ? EValue.ArName : EValue.ArValue}
                  />
                </div>
              </SingleFieldList>
            </ArrayField>
          )}
        />
        <WithRecord
          label="Stock"
          render={(record) => (
            <NumberField
              source={EVariant.Stock}
              sortable={false}
              sx={{
                color: record[EVariant.Stock] === 0 ? colors.redAccent[500] : colors.neutral[900],
              }}
            />
          )}
        />
        <NumberField source={EVariant.Cost} sortable />
        <BooleanField source={EVariant.IsActive} sortable={false} />
        <BooleanField source={EVariant.IsReserved} sortable={false} />
        <TextField
          source={`${EVariant.Product}.${EValue.Brand}.${EValue.EnName}`}
          label="Brand Name"
          sortable={false}
        />
        <WrapperField label="Variant Image" sortable={false}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImageField
              source={`${EVariant.VariantImage}`}
              sx={{
                '& img': {
                  maxWidth: 50,
                  maxHeight: 50,
                  objectFit: 'cover !important',
                  borderRadius: '50%',
                },
                display: 'flex',
                marginRight: '8px',
              }}
            />
          </div>
        </WrapperField>
        <NumberField source={EVariant.MarketPlaceCount} label="Activated in Marketplaces" textAlign="left" sortable />
        <NumberField source={`${EVariant.Product}.${EValue.Id}`} textAlign="left" label="Product Id" sortable={false} />
        <ChipField
          sx={{
            backgroundColor: colors.accent.pink,
            color: colors.neutral[0],
            padding: 0,
          }}
          source={`${EVariant.Product}.${EValue.Type}`}
          label="Type"
          sortable={false}
        />

        <LabelField source={`${EVariant.Product}.${EValue.Provider}`} sortable={false} />
        <WithRecord
          label="Provider Metadata"
          render={(record) => {
            if (record.product.provider === EProvider.ASIACELL) {
              return (
                <Box display="flex" flexDirection="column">
                  <Box display="flex" color={colors.accent.pink}>
                    ID:&nbsp;
                    <NumberField source={`${EVariant.ProviderMetaData}.id`} sortable={false} />
                  </Box>

                  <TextField source={`${EVariant.ProviderMetaData}.name`} sortable={false} />
                </Box>
              )
            } else if (record.product.provider === EProvider.MINTROUTE) {
              return (
                <Box display="flex" flexDirection="column">
                  <Box display="flex" color={colors.accent.pink}>
                    ID:&nbsp;
                    <TextField source={`${EVariant.ProviderMetaData}.id`} sortable={false} />
                  </Box>
                  <Box display="flex">
                    Min:&nbsp;
                    <NumberField source={`${EVariant.ProviderMetaData}.min`} sortable={false} />
                  </Box>
                  <Box display="flex">
                    Max:&nbsp;
                    <NumberField source={`${EVariant.ProviderMetaData}.max`} sortable={false} />
                  </Box>
                </Box>
              )
            } else return <div />
          }}
        />

        <NumberField source={EVariant.Order} textAlign="left" sortable />

        <DateField source={EVariant.CreatedAt} sortable={false} />
        <DateField source={EVariant.UpdatedAt} sortable={false} />
      </Datagrid>
    </List>
  )
}
