import { Box } from '@mui/material'
import { Create, SimpleForm, DateTimeInput, ReferenceInput, SelectInput, required, TextInput } from 'react-admin'
import { EMatch, ETeam } from './types'
import { EStadium } from '../Stadiums/types'
import { dateTimeZoneFormatters } from '@/services/api/helpers'

export const MatchesCreate = () => {
  return (
    <Create title="Create new Match" redirect="list">
      <SimpleForm>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.ArTitle} label="Title (Arabic)" fullWidth />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.KuTitle} label="Title (Kurdish)" fullWidth />
            </Box>
          </Box>

          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.ArSubtitle} label="Subtitle (Arabic)" fullWidth />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.KuSubtitle} label="Subtitle (Kurdish)" fullWidth />
            </Box>
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.ArAddress} label="Address (Arabic)" fullWidth />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.KuAddress} label="Address (Kurdish)" fullWidth />
            </Box>
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <DateTimeInput source={EMatch.DateAndTime} fullWidth required {...dateTimeZoneFormatters} />
          </Box>

          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceInput source={EMatch.TeamAId} reference="teams" perPage={1000}>
                <SelectInput optionText={ETeam.ArName} label="Team A" validate={required()} fullWidth />
              </ReferenceInput>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceInput source={EMatch.TeamBId} reference="teams" perPage={1000}>
                <SelectInput optionText={ETeam.ArName} label="Team B" validate={required()} fullWidth />
              </ReferenceInput>
            </Box>
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source={EMatch.StadiumId} reference="stadiums" perPage={1000}>
              <SelectInput optionText={EStadium.ArName} label="Stadium" validate={required()} fullWidth />
            </ReferenceInput>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}
