import { apiPasswordChange } from '@/services/api/methods/apiPasswordChange'
import { getSession, removeSession, setAccessToken, setRefreshToken } from '@/services/api/helpers'
import { Login, useRedirect, useNotify, SimpleForm, TextInput } from 'react-admin'
import { DASHBOARD, LOGIN } from '@/services/api/routes'
import { Alert } from '@mui/material'
import { requiredTrimmed } from '@/utils/validation'

interface FormData {
  newPassword?: string
}

interface ErrorObject {
  [key: string]: string
}

export const ResetPassword = () => {
  const redirect = useRedirect()
  const notify = useNotify()
  const session = JSON.parse(getSession() || '{}')

  const validatePassword = ({ newPassword, repeatPassword }: { newPassword?: string; repeatPassword?: string }) => {
    const errors: ErrorObject = {}
    if (!newPassword || newPassword !== repeatPassword) {
      errors.repeatPassword = 'Passwords do not match. Please repeat the password'
    }
    return errors
  }

  const submit = ({ newPassword }: FormData) => {
    if (session && session.id && newPassword) {
      apiPasswordChange({
        session: session.id,
        username: session.username,
        newPassword,
      })
        .then(({ accessToken, refreshToken }) => {
          setAccessToken(accessToken)
          setRefreshToken(refreshToken)
          removeSession()
          redirect(DASHBOARD())
        })
        .catch(() => {
          notify(<Alert severity="error">No active session. Redirect to login</Alert>)
          removeSession()
          redirect(LOGIN())
        })
    } else {
      redirect(LOGIN())
      notify(<Alert severity="error">No active session. Redirect to login</Alert>)
    }
  }

  return (
    <Login>
      <SimpleForm
        validate={validatePassword}
        onSubmit={submit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px',
        }}
      >
        <TextInput
          source="newPassword"
          label="New Password"
          type="newPassword"
          autoComplete="new-password"
          validate={requiredTrimmed}
          fullWidth
        />
        <TextInput
          source="repeatPassword"
          label="Repeat Password"
          type="repeatPassword"
          autoComplete="repeat-password"
          validate={requiredTrimmed}
          fullWidth
        />
      </SimpleForm>
    </Login>
  )
}
