import { Edit, SimpleForm, TextInput, ArrayInput, TextField, SimpleFormIterator, NumberInput } from 'react-admin'
import { EStadium } from './types'

export const StadiumsEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source={EStadium.ArName} required />
        <TextInput source={EStadium.KuName} required />
        <TextInput source={EStadium.ChartKey} required fullWidth />
        <ArrayInput source={EStadium.Categories}>
          <SimpleFormIterator disableClear disableRemove disableAdd disableReordering fullWidth>
            <TextField source="label" />
            <NumberInput source="price" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}
