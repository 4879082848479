import { TStadium } from '@/modules/Stadiums/types'
import { TMainCategory } from '@/modules/MainCategories/types'

export enum EMatch {
  Id = 'id',
  TeamA = 'teamA',
  TeamB = 'teamB',
  ArTitle = 'arTitle',
  ArSubtitle = 'arSubtitle',
  KuTitle = 'kuTitle',
  KuSubtitle = 'kuSubtitle',
  ArAddress = 'arAddress',
  KuAddress = 'kuAddress',
  Stadium = 'stadium',
  DateAndTime = 'dateAndTime',
  Categories = 'categories',
  EventKey = 'eventKey',
  SeasonId = 'seasonId',
  RoundId = 'roundId',
  StadiumId = 'stadiumId',
  TeamAId = 'teamAId',
  TeamBId = 'teamBId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MinDateAndTime = 'minDateAndTime',
  MaxDateAndTime = 'maxDateAndTime',
  ArTitleLike = 'arTitleLike',
  ArSubtitleLike = 'arSubtitleLike',
  KuTitleLike = 'kuTitleLike',
  KuSubtitleLike = 'kuSubtitleLike',
  PublishedAt = 'publishedAt',
}

export enum ETeam {
  ArName = 'arName',
  KuName = 'kuName',
  Logo = 'string',
  Id = 'id',
}

export type TTeam = {
  [ETeam.ArName]: string
  [ETeam.KuName]: string
  [ETeam.Logo]: string
  [ETeam.Id]: number
}

export type TMatch = {
  [EMatch.TeamA]: TTeam
  [EMatch.TeamB]: TTeam
  [EMatch.ArTitle]: string
  [EMatch.ArSubtitle]: string
  [EMatch.KuTitle]: string
  [EMatch.KuSubtitle]: string
  [EMatch.ArAddress]: string
  [EMatch.KuAddress]: string
  [EMatch.Stadium]: TStadium
  [EMatch.DateAndTime]: Date
  [EMatch.Categories]: TMainCategory[]
  [EMatch.EventKey]: string
  [EMatch.Id]: number
  [EMatch.CreatedAt]: number
  [EMatch.UpdatedAt]: number
  [EMatch.PublishedAt]: Date
}
