export enum EUser {
  Id = 'id',
  Name = 'name',
  Phone = 'phone',
  OrdersCount = 'ordersCount',
  IsBlocked = 'isBlocked',
  CreatedAt = 'createdAt',
  NameLike = 'nameLike',
}

export type TUser = {
  [EUser.Id]: string
  [EUser.Name]: string
  [EUser.Phone]: string
  [EUser.CreatedAt]: Date
  [EUser.OrdersCount]: number
  [EUser.IsBlocked]: boolean
}
