import { useEffect } from 'react'
import { Login, useRedirect } from 'react-admin'
import { getSession } from '@/services/api/helpers'
import { RESET_PASSWORD } from '@/services/api/routes'

export const LoginPage = () => {
  const redirect = useRedirect()
  const session = JSON.parse(getSession() || '{}')

  useEffect(() => {
    return () => {
      if (session && session.id) {
        redirect(RESET_PASSWORD())
      }
    }
  }, [session])

  return <Login />
}
