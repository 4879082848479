import { useEffect } from 'react'
import {
  NumberInput,
  AutocompleteInput,
  required,
  ArrayInput,
  TextInput,
  SimpleFormIterator,
  useChoicesContext,
  FormDataConsumer,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { Box } from '@mui/material'

import { EVariant, EValue } from '@/modules/Variants/types'
import { EProduct, EProvider, EType } from '@/modules/Products/types'
import { requiredTrimmed } from '@/utils/validation'

export const VariantsValueInput = ({ isCreate }: { isCreate?: boolean }) => {
  const { selectedChoices } = useChoicesContext()
  const { setValue } = useFormContext()
  if (!selectedChoices || !selectedChoices[0])
    return (
      <AutocompleteInput
        optionText={(record) => `${record[EProduct.EnName]} - #${record.id}`}
        validate={required()}
        fullWidth
      />
    )
  const { type, provider } = selectedChoices[0]

  return (
    <>
      <AutocompleteInput
        optionText={(record) => `${record[EProduct.EnName]} - #${record.id}`}
        validate={required()}
        fullWidth
      />
      <Box display="flex" flexDirection="column">
        <span>{`Variant Value (${type})`}</span>
        <FormDataConsumer>
          {({ formData }) => {
            const initialValue = formData?.value
            const initialVoucherValue = initialValue && initialValue[0]

            useEffect(() => {
              if (formData.type !== type) {
                setValue(EVariant.Type, type)
              }
            }, [type])

            return (
              <>
                <ArrayInput
                  source={EVariant.BundleValue}
                  defaultValue={
                    isCreate
                      ? [
                          { [EValue.EnName]: '', [EValue.ArName]: '' },
                          { [EValue.EnValue]: '', [EValue.ArValue]: '', [EValue.Logo]: ' ' },
                        ]
                      : initialValue.length > 1
                      ? initialValue
                      : [initialVoucherValue, { [EValue.EnValue]: '', [EValue.ArValue]: '', [EValue.Logo]: ' ' }]
                  }
                  // ArrayInputs needs to bo hidden this way, due to complexity of defaultValues of this dynamic form, depending on product type
                  sx={{ display: type === EType.BUNDLE ? 'block' : 'none' }}
                >
                  <SimpleFormIterator
                    inline
                    disableClear
                    disableReordering
                    disableRemove={formData[EVariant.BundleValue]?.length <= 2}
                  >
                    {type === EType.BUNDLE && (
                      <FormDataConsumer>
                        {({ getSource }) =>
                          getSource &&
                          (getSource(EVariant.BundleValue).includes('.0') ? (
                            <>
                              <TextInput
                                source={getSource(EValue.EnName)}
                                helperText={false}
                                required
                                validate={requiredTrimmed}
                              />
                              <TextInput
                                source={getSource(EValue.ArName)}
                                helperText={false}
                                required
                                validate={requiredTrimmed}
                              />
                            </>
                          ) : (
                            <>
                              <TextInput source={getSource(EValue.EnValue)} required validate={requiredTrimmed} />
                              <TextInput
                                source={getSource(EValue.ArValue)}
                                helperText={false}
                                required
                                validate={requiredTrimmed}
                              />
                              <TextInput source={getSource(EValue.Logo)} helperText={'should be .svg file'} />
                            </>
                          ))
                        }
                      </FormDataConsumer>
                    )}
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                  source={EVariant.Type}
                  defaultValue={type}
                  sx={{ visibility: 'hidden', height: '0 !important' }}
                />
                <ArrayInput
                  source={EVariant.VoucherValue}
                  defaultValue={
                    isCreate
                      ? [{ [EValue.EnValue]: '', [EValue.ArValue]: '' }]
                      : [
                          {
                            [EValue.EnValue]: initialVoucherValue[EValue.EnValue],
                            [EValue.ArValue]: initialVoucherValue[EValue.ArValue],
                          },
                        ]
                  }
                  sx={{ display: type === EType.VOUCHER ? 'block' : 'none' }}
                >
                  <SimpleFormIterator
                    inline
                    disableClear
                    disableAdd={formData[EVariant.VoucherValue]?.length >= 1}
                    disableRemove={formData[EVariant.VoucherValue]?.length <= 1}
                    disableReordering
                  >
                    <TextInput
                      source={EValue.EnValue}
                      helperText={false}
                      required={type === EType.VOUCHER}
                      validate={type === EType.VOUCHER ? requiredTrimmed : []}
                    />
                    <TextInput
                      source={EValue.ArValue}
                      helperText={false}
                      required={type === EType.VOUCHER}
                      validate={type === EType.VOUCHER ? requiredTrimmed : []}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            )
          }}
        </FormDataConsumer>
        <Box display="flex" flexDirection="column" border="1px solid gray" padding="12px" marginTop="12px">
          <span>{`Provider Meta Data (${provider})`}</span>
          {provider === EProvider.ASIACELL && (
            <>
              <NumberInput source={`${EVariant.ProviderMetaData}.id`} fullWidth required />
              <TextInput source={`${EVariant.ProviderMetaData}.name`} fullWidth required />
            </>
          )}
          {provider === EProvider.MINTROUTE && (
            <>
              <NumberInput source={`${EVariant.ProviderMetaData}.id`} fullWidth required />
              <NumberInput source={`${EVariant.ProviderMetaData}.min`} fullWidth />
              <NumberInput source={`${EVariant.ProviderMetaData}.max`} fullWidth />
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
