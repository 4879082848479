import { GetListParams } from 'react-admin'

import { feesMock } from '@/modules/Fees/mocks'
import { TFee } from '@/modules/Fees/types'

const responseMapper = (data: TFee[]) => {
  return { data, total: data.length }
}

// TODO: remove mock, add API request

export const getAllFees = async (params: GetListParams) => {
  const { data } = await Promise.resolve<{ data: TFee[] }>({
    data: feesMock,
  })

  return responseMapper(data)
}
