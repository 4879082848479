export enum EFee {
  Id = 'id',
  Card = 'card',
  Price = 'price',
  TasdidFees = 'tasdidFees',
  QiFees = 'qiFees',
  APSFees = 'apsFees',
  HawalaFees = 'hawalaFees',
  WalletFees = 'walletFees',
  CardsType = 'cardsType',
  Condition = 'condition',
}

export enum ECard {
  Name = 'name',
  Url = 'url',
}

type TCondition = 'available' | 'unavailable'

export type TCard = {
  [ECard.Name]: string
  [ECard.Url]: string
}

export type TFee = {
  [EFee.Id]: string
  [EFee.Card]: TCard
  [EFee.Price]: number
  [EFee.TasdidFees]: number
  [EFee.QiFees]: number
  [EFee.APSFees]: number
  [EFee.HawalaFees]: number
  [EFee.WalletFees]: number
  [EFee.CardsType]: string
  [EFee.Condition]: TCondition
}
