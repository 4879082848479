import { AppBar } from 'react-admin'
import logo from '@/assets/logo.svg'
import { tokens } from '@/Layout/theme'

export const NavBar = (props: object) => {
  const colors = tokens()

  return (
    <AppBar
      sx={{
        '& .RaAppBar-toolbar': {
          width: '100%',
          height: '56px',
          justifyContent: 'space-between',
          backgroundColor: colors.neutral[800],
        },
      }}
      color="primary"
      {...props}
    >
      <img src={logo} alt="DigitalZone logo" style={{ marginLeft: 8, height: 32 }} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginLeft: 0,
          alignItems: 'center',
        }}
      />
    </AppBar>
  )
}
