export enum EStadium {
  Id = 'id',
  ChartKey = 'chartKey',
  ArName = 'arName',
  KuName = 'kuName',
  Categories = 'categories',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ArNameLike = 'arNameLike',
  KuNameLike = 'kuNameLike',
  ChartKeyLike = 'chartKeyLike',
}

export type TStadium = {
  [EStadium.Id]: number
  [EStadium.ChartKey]: string
  [EStadium.ArName]: string
  [EStadium.KuName]: string
  [EStadium.Categories]: []
  [EStadium.CreatedAt]: Date
  [EStadium.UpdatedAt]: Date
}

export type TCreateStadium = {
  createdId: string
}
