import { defaultTheme } from 'react-admin'
import createTheme from '@mui/material/styles/createTheme'

export const tokens = () => ({
  neutral: {
    0: '#FFF',
    50: '#F8F8F9',
    100: '#F3F3F5',
    200: '#E6E8EA',
    300: '#B5BAC1',
    400: '#848C98',
    500: '#535E6F',
    600: '#3A475A',
    700: '#223046',
    800: '#091931',
    900: '#050F1D',
  },
  primary: {
    100: '#F5FBFB',
    200: '#E6F6F8',
    300: '#CDECF0',
    400: '#9CDAE1',
    500: '#6AC7D2',
    600: '#07A2B4',
    700: '#0692A2',
    800: '#068290',
    900: '#04616C',
  },
  cyanosed: {
    100: '#F8FBFC',
    200: '#ECF3F4',
    300: '#CBDDE1',
    400: '#ABC0C4',
    500: '#73969D',
    600: '#4F7982',
    700: '#3F6168',
  },
  redAccent: {
    100: '#FBEBE9',
    500: '#D63920',
    800: '#B52610',
  },
  greenAccent: {
    100: '#E7F8F0',
    500: '#10B56A',
    800: '#057E47',
  },
  accent: {
    lemon: '#FFEF9A',
    violet: '#799AFF',
    mint: '#A4FCDC',
    sky: '#9BE6EB',
    emerald: '#269889',
    coral: '#FF7E79',
    pink: '#fd397a',
  },
})

const colors = tokens()

export const appTheme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: colors.primary[700],
      light: colors.primary[300],
      dark: colors.primary[900],
    },
    secondary: {
      main: colors.cyanosed[400],
      light: colors.cyanosed[200],
      dark: colors.cyanosed[600],
    },
    error: colors.redAccent,
    background: {
      default: colors.cyanosed[100],
    },
  },
  typography: {
    fontFamily: ['Noto sans', 'sans-serif'].join(','),
    fontSize: 12,
    h1: {
      fontFamily: ['Noto sans', 'sans-serif'].join(','),
      fontSize: 42,
    },
    h2: {
      fontFamily: ['Noto sans', 'sans-serif'].join(','),
      fontSize: 32,
    },
    h3: {
      fontFamily: ['Noto sans', 'sans-serif'].join(','),
      fontSize: 28,
    },
    h4: {
      fontFamily: ['Noto sans', 'sans-serif'].join(','),
      fontSize: 20,
    },
    h5: {
      fontFamily: ['Noto sans', 'sans-serif'].join(','),
      fontSize: 16,
    },
    h6: {
      fontFamily: ['Noto sans', 'sans-serif'].join(','),
      fontSize: 14,
    },
  },
})
