import { NumberField, Show, SimpleShowLayout, TextField, DateField, ChipField } from 'react-admin'
import { tokens } from '@/Layout/theme'
import { EResource } from '@/admin/types'
import { EPromocode } from '@/modules/Promocodes/types'
import { EProduct } from '@/modules/Products/types'

import { LabelField } from '@/components/LabelField'
import { EOrder, EConsumer } from '@/modules/Orders/types'

export const OrderShow = () => {
  const colors = tokens()
  return (
    <Show resource={EResource.Orders}>
      <SimpleShowLayout>
        <NumberField source={EOrder.Id} label="ID" textAlign="left" />
        <LabelField source={EOrder.Status} label="Status" />
        <TextField source={`${EOrder.PromoCode}${EPromocode.Id}`} label="Promocode Id" />
        <TextField source={`${EOrder.PromoCode}${EPromocode.Value}`} label="Promocode Value" />
        <TextField source={EOrder.TransactionId} />
        <DateField source={EOrder.CreatedAt} showTime />
        <TextField source={EOrder.PaymentMethod} />
        <TextField source={EOrder.TotalPrice} />
        <TextField source={`${EOrder.Consumer}.${EConsumer.Id}`} sortable={false} label="Consumer ID" />
        <TextField source={`${EOrder.Consumer}.${EConsumer.Phone}`} sortable={false} label="Consumer Phone" />
        <TextField source={`${EOrder.Product}.${EProduct.Id}`} sortable={false} label="Product ID" />
        <ChipField
          sx={{
            backgroundColor: colors.accent.pink,
            color: colors.neutral[0],
          }}
          source={`${EOrder.Product}.${EProduct.EnName}`}
          label="Product English Name"
          sortable={false}
        />
      </SimpleShowLayout>
    </Show>
  )
}
