import { ERole } from '@/types/data'

export enum EAdmin {
  Id = 'id',
  Role = 'role',
  Username = 'username',
  Deleted = 'deleted',
  IsActive = 'isActive',
  BrandIds = 'brandIds',
  MerchantId = 'merchantId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MerchantName = 'merchantName',
  Password = 'password',
  UsernameLike = 'usernameLike',
}

export type TAdmin = {
  [EAdmin.Id]: string
  [EAdmin.Role]: ERole
  [EAdmin.Username]: string
  [EAdmin.Deleted]: boolean
  [EAdmin.IsActive]: boolean
  [EAdmin.BrandIds]: number[]
  [EAdmin.MerchantId]: string
  [EAdmin.CreatedAt]: Date
  [EAdmin.UpdatedAt]: Date
}
