import { ChipField, useRecordContext } from 'react-admin'
import Chip from '@mui/material/Chip'

import { EVariant } from '@/modules/Variants/types'
import { EStatus } from '@/modules/Orders/types'

export const LabelField = (props: { source: string; label?: string; sortable?: boolean }) => {
  const record = useRecordContext()
  const isBool = typeof record[props.source] === 'boolean'

  const getValue = () => {
    if (isBool) {
      switch (props.source) {
        case EVariant.IsReserved:
          return record[props.source] ? 'reserved' : 'not reserved'
        default:
          return record[props.source] ? 'available' : 'not available'
      }
    }
    return record[props.source] || ''
  }

  const getColor = () => {
    switch (record[props.source]) {
      case true:
      case 'available':
      case 'success':
      case EStatus.SUCCESS:
      case 'pop':
      case 'active':
        return 'blue'
      case 'created':
      case EStatus.PENDING:
        return 'gray'
      default:
        return 'red'
    }
  }
  return isBool ? (
    <Chip
      label={getValue()}
      sx={{
        color: getColor(),
        textTransform: 'capitalize',
      }}
    />
  ) : (
    <ChipField
      sx={{
        color: getColor(),
        textTransform: 'capitalize',
      }}
      {...props}
    />
  )
}
