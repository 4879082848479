import { tokens } from '@/Layout/theme'

const colors = tokens()

export const mockBarData = [
  {
    country: 'January',
    'Yalla Ludo': 137,
    'Yalla LudoColor': 'hsl(229, 70%, 50%)',
    PUBG: 96,
    PUBGColor: 'hsl(296, 70%, 50%)',
    IQD: 72,
    IQDColor: 'hsl(97, 70%, 50%)',
    RazerGold: 140,
    RazerGoldColor: 'hsl(340, 70%, 50%)',
    Other: 12,
  },
  {
    country: 'February',
    'Yalla Ludo': 55,
    'Yalla LudoColor': 'hsl(307, 70%, 50%)',
    PUBG: 28,
    PUBGColor: 'hsl(111, 70%, 50%)',
    IQD: 58,
    IQDColor: 'hsl(273, 70%, 50%)',
    RazerGold: 29,
    RazerGoldColor: 'hsl(275, 70%, 50%)',
    Other: 162,
  },
  {
    country: 'March',
    'Yalla Ludo': 109,
    'Yalla LudoColor': 'hsl(72, 70%, 50%)',
    PUBG: 23,
    PUBGColor: 'hsl(96, 70%, 50%)',
    IQD: 34,
    IQDColor: 'hsl(106, 70%, 50%)',
    RazerGold: 152,
    RazerGoldColor: 'hsl(256, 70%, 50%)',
    Other: 22,
  },
  {
    country: 'April',
    'Yalla Ludo': 133,
    'Yalla LudoColor': 'hsl(257, 70%, 50%)',
    PUBG: 52,
    PUBGColor: 'hsl(326, 70%, 50%)',
    IQD: 43,
    IQDColor: 'hsl(110, 70%, 50%)',
    RazerGold: 83,
    RazerGoldColor: 'hsl(9, 70%, 50%)',
    Other: 102,
  },
  {
    country: 'May',
    'Yalla Ludo': 81,
    'Yalla LudoColor': 'hsl(190, 70%, 50%)',
    PUBG: 80,
    PUBGColor: 'hsl(325, 70%, 50%)',
    IQD: 112,
    IQDColor: 'hsl(54, 70%, 50%)',
    RazerGold: 35,
    RazerGoldColor: 'hsl(285, 70%, 50%)',
    Other: 182,
  },
  {
    country: 'June',
    'Yalla Ludo': 66,
    'Yalla LudoColor': 'hsl(208, 70%, 50%)',
    PUBG: 111,
    PUBGColor: 'hsl(334, 70%, 50%)',
    IQD: 167,
    IQDColor: 'hsl(182, 70%, 50%)',
    RazerGold: 18,
    RazerGoldColor: 'hsl(76, 70%, 50%)',
    Other: 152,
  },
  {
    country: 'July',
    'Yalla Ludo': 80,
    'Yalla LudoColor': 'hsl(87, 70%, 50%)',
    PUBG: 47,
    PUBGColor: 'hsl(141, 70%, 50%)',
    IQD: 158,
    Other: 122,
    IQDColor: 'hsl(224, 70%, 50%)',
    RazerGold: 49,
    RazerGoldColor: 'hsl(274, 70%, 50%)',
  },
]

export const mockLineData = [
  {
    id: 'Razer Gold',
    color: colors.primary[100],
    data: [
      {
        x: 'January',
        y: 101,
      },
      {
        x: 'February',
        y: 75,
      },
      {
        x: 'March',
        y: 36,
      },
      {
        x: 'April',
        y: 216,
      },
      {
        x: 'May',
        y: 35,
      },
      {
        x: 'June',
        y: 236,
      },
      {
        x: 'July',
        y: 88,
      },
      {
        x: 'August',
        y: 232,
      },
      {
        x: 'September',
        y: 281,
      },
      {
        x: 'October',
        y: 1,
      },
      {
        x: 'November',
        y: 35,
      },
      {
        x: 'December',
        y: 14,
      },
    ],
  },
  {
    id: 'Yalla Ludo',
    color: colors.primary[700],
    data: [
      {
        x: 'January',
        y: 212,
      },
      {
        x: 'February',
        y: 190,
      },
      {
        x: 'March',
        y: 270,
      },
      {
        x: 'April',
        y: 9,
      },
      {
        x: 'May',
        y: 75,
      },
      {
        x: 'June',
        y: 175,
      },
      {
        x: 'July',
        y: 33,
      },
      {
        x: 'August',
        y: 189,
      },
      {
        x: 'September',
        y: 97,
      },
      {
        x: 'October',
        y: 87,
      },
      {
        x: 'November',
        y: 299,
      },
      {
        x: 'December',
        y: 251,
      },
    ],
  },
  {
    id: 'PUBG',
    color: colors.accent.violet,
    data: [
      {
        x: 'January',
        y: 191,
      },
      {
        x: 'February',
        y: 136,
      },
      {
        x: 'March',
        y: 91,
      },
      {
        x: 'April',
        y: 190,
      },
      {
        x: 'May',
        y: 211,
      },
      {
        x: 'June',
        y: 152,
      },
      {
        x: 'July',
        y: 189,
      },
      {
        x: 'August',
        y: 152,
      },
      {
        x: 'September',
        y: 8,
      },
      {
        x: 'October',
        y: 197,
      },
      {
        x: 'November',
        y: 107,
      },
      {
        x: 'December',
        y: 170,
      },
    ],
  },
]
