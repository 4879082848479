import { ESettlement, TSettlement } from './types'

export const settlementsMock: TSettlement[] = [
  {
    [ESettlement.Id]: '19',
    [ESettlement.AdminID]: '6',
    [ESettlement.Amount]: 340000.0,
    [ESettlement.SMS]: '100711',
    [ESettlement.Motive]: 'Aya',
    [ESettlement.Comment]: 'No',
    [ESettlement.Picture]:
      'https://foremanbrosinc.com/wp-content/uploads/2017/05/1c0d0f0cb8b7f2fb2685da9798efe42b_big-image-png-image-placeholder-clipart_2400-2400.png',
  },
  {
    [ESettlement.Id]: '12',
    [ESettlement.AdminID]: '1',
    [ESettlement.Amount]: 100000,
    [ESettlement.SMS]: '010198',
    [ESettlement.Motive]: 'Aya',
    [ESettlement.Comment]: 'No',
    [ESettlement.Picture]:
      'https://foremanbrosinc.com/wp-content/uploads/2017/05/1c0d0f0cb8b7f2fb2685da9798efe42b_big-image-png-image-placeholder-clipart_2400-2400.png',
  },
  {
    [ESettlement.Id]: '8',
    [ESettlement.AdminID]: '6',
    [ESettlement.Amount]: 340000.0,
    [ESettlement.SMS]: '010198',
    [ESettlement.Motive]: 'Aya',
    [ESettlement.Comment]: 'No',
    [ESettlement.Picture]:
      'https://foremanbrosinc.com/wp-content/uploads/2017/05/1c0d0f0cb8b7f2fb2685da9798efe42b_big-image-png-image-placeholder-clipart_2400-2400.png',
  },
]
