const isServer = typeof window === 'undefined';

export const storageSet = (key: string, value: string) => {
  if (isServer) return;
  window.localStorage.setItem(key, value);
};

export const storageGet = (key: string) => {
  if (isServer) return;
  return window.localStorage.getItem(key);
};

export const storageRemove = (key?: string) => {
  if (isServer) return;
  if (key) {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.clear();
  }
};
