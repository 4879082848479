import { TBrand } from '../Brands/types'

export enum EVariant {
  Id = 'id',
  Price = 'price',
  Value = 'value',
  IsActive = 'isActive',
  IsReserved = 'isReserved',
  Order = 'order',
  Stock = 'stock',
  Product = 'product',
  ProductId = 'productId',
  CreatedAt = 'createdAt',
  ParentProductType = 'parentProductType',
  ParentProductId = 'parentProductId',
  BrandName = 'brandName',
  UpdatedAt = 'updatedAt',
  ProviderMetaData = 'providerMetaData',
  VoucherValue = 'voucherValue',
  BundleValue = 'bundleValue',
  Type = 'type',
  VariantImage = 'image',
  Cost = 'cost',
  MarketPlaceCount = 'marketplaceCount',
}

export enum EValue {
  Id = 'id',
  ArName = 'arName',
  EnName = 'enName',
  Logo = 'logo',
  EnValue = 'enValue',
  ArValue = 'arValue',
  Provider = 'provider',
  Type = 'type',
  Brand = 'brand',
}

export type TValue = {
  [EValue.ArName]: string
  [EValue.EnName]: string
  [EValue.Logo]: string
  [EValue.EnValue]: string
  [EValue.ArValue]: string
}

export type TProduct = {
  [EValue.Id]: number
  [EValue.ArName]: string
  [EValue.EnName]: string
  [EValue.Logo]: string
  [EValue.Brand]: TBrand
  [EValue.Type]: string
}

export type TVariant = {
  [EVariant.Id]: number
  [EVariant.Price]: number
  [EVariant.Value]: TValue[]
  [EVariant.IsActive]: boolean
  [EVariant.IsReserved]: boolean
  [EVariant.Order]: number
  [EVariant.Stock]: number
  [EVariant.Product]: TProduct
  [EVariant.CreatedAt]: Date
  [EVariant.ProviderMetaData]?: object
}

export type TValues = {
  [EVariant.Id]?: number
  [EVariant.ProviderMetaData]?: object
  [EVariant.Value]?: []
  [EVariant.Type]?: string
  [EVariant.VoucherValue]?: []
  [EVariant.BundleValue]?: []
}
