import axios from 'axios'
import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { getAccessToken } from '@/services/api//helpers'
import { Identifier } from 'react-admin'

export const postResendSms = async (
  id: Identifier,
  isTicketOrders: boolean,
  notify: (msg?: string, typeObj?: object) => void,
) => {
  const accessToken = getAccessToken()

  try {
    await axios({
      method: 'post',
      url: `${API_AUTH_BASE_URL}/dashboard/${isTicketOrders ? 'ticket-orders' : 'tickets'}/${id}/confirm`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    notify('SMS sent successfully', { type: 'success' })
  } catch (error: any) {
    notify(`SMS sending error: ${error.response.data.message}`, { type: 'error' })
    throw new Error((error as Error).message)
  }
}
