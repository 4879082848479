import { Edit, SimpleForm, TextInput, useRedirect } from 'react-admin'
import { ImageInputField } from '@/components/ImageInputField'
import { putAWSImageFIle } from '@/services/api/methods/putAWSImageFile'
import { ETeam, AWSUrlType } from './types'
import { TEAMS } from '@/services/api/routes'

export const TeamsEdit = () => {
  const redirect = useRedirect()
  const transform = (values: {
    logo?: {
      file: File
      imageUploadUrl: AWSUrlType
    }
  }) => {
    const { imageUploadUrl, file } = values.logo || {}
    if (imageUploadUrl && file) {
      putAWSImageFIle(imageUploadUrl.uploadUrl, file)
    }
    setTimeout(() => redirect(TEAMS()), 1500)
    return { ...values, logo: imageUploadUrl?.fileUrl }
  }

  return (
    <Edit transform={transform} mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        <TextInput source={ETeam.ArName} required />
        <TextInput source={ETeam.KuName} required />
        <ImageInputField isEdit />
      </SimpleForm>
    </Edit>
  )
}
