import { NO_WHITESPACE } from '@/consts/regexp'
import { ADMINS } from '@/services/api/routes'
import { ERoleRequest } from '@/types/data'
import { Box } from '@mui/material'
import {
  AutocompleteArrayInput,
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  ValidateForm,
  regex,
  required,
  useNotify,
  useRedirect,
} from 'react-admin'
import { EBrand } from '../Brands/types'
import { adminsChoices } from './consts'
import { EAdmin } from './types'

const validatePasswordRegex = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!?@#$%^&+=]).*$/

const validateUserCreation: ValidateForm = (values) => {
  const errors: {
    role?: string
    password?: string
    merchantName?: string
    brandIds?: string
    username?: string
  } = {}

  if (!values.role) {
    errors.role = 'ra.validation.required'
  }
  if (!values.password) {
    errors.password = 'ra.validation.required'
  } else if (!values.password.match(validatePasswordRegex)) {
    errors.password = 'Must be 8 characters, contain one special sign and uppercase letter'
  } else if (!values.password.match(NO_WHITESPACE)) {
    errors.password = 'No leading spaces allowed'
  }
  if (values.role === ERoleRequest.Merchant && !values.merchantId && !values.merchantName) {
    errors.merchantName = 'Either Merchant Id or Merchant Name is required!'
  }
  if (values.role === ERoleRequest.Supplier && !values.brandIds?.length) {
    errors.brandIds = 'ra.validation.required'
  }
  if (!values.username) {
    errors.username = 'ra.validation.required'
  } else if (!values.username.match(NO_WHITESPACE)) {
    errors.username = 'No leading spaces allowed'
  }
  return errors
}

export const AdminsCreate = () => {
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = ({ data }: { data: { role: string } }) => {
    notify(`New ${data.role} user created`, { type: 'success' })
    redirect(ADMINS())
  }

  return (
    <Create title="Create new Admin" redirect="list" mutationOptions={{ onSuccess }}>
      <SimpleForm validate={validateUserCreation} sanitizeEmptyValues>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <TextInput source={EAdmin.Username} validate={required()} label="User Name" fullWidth />
          <SelectInput
            source={EAdmin.Role}
            choices={adminsChoices}
            optionValue="name"
            fullWidth
            validate={required()}
          />
          <FormDataConsumer>
            {({ formData }) => {
              switch (formData.role) {
                case ERoleRequest.Merchant:
                  return (
                    <>
                      <TextInput
                        source={EAdmin.MerchantId}
                        label="Merchant Id"
                        validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
                        fullWidth
                      />
                      <TextInput
                        source={EAdmin.MerchantName}
                        label="Merchant Name"
                        validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
                        fullWidth
                      />
                    </>
                  )
                case ERoleRequest.Supplier:
                  return (
                    <ReferenceArrayInput source={EAdmin.BrandIds} reference="brands" perPage={100}>
                      <AutocompleteArrayInput optionText={EBrand.EnName} fullWidth validate={required()} />
                    </ReferenceArrayInput>
                  )
                default:
                  return <div />
              }
            }}
          </FormDataConsumer>
          <TextInput
            source={EAdmin.Password}
            fullWidth
            validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
            required
          />
        </Box>
      </SimpleForm>
    </Create>
  )
}
