import { ERoleRequest } from '@/types/data'

export const adminsChoices = [
  {
    id: 0,
    name: ERoleRequest.Admin,
  },
  {
    id: 1,
    name: ERoleRequest.Superadmin,
  },
  {
    id: 2,
    name: ERoleRequest.Merchant,
  },
  {
    id: 3,
    name: ERoleRequest.Supplier,
  },
  {
    id: 4,
    name: ERoleRequest.TZadmin,
  },
  {
    id: 5,
    name: ERoleRequest.EXTERNAL_ENTITY,
  },
]

export const adminsEditChoices = [
  {
    id: 0,
    name: ERoleRequest.Admin,
  },
  {
    id: 1,
    name: ERoleRequest.Superadmin,
  },
  {
    id: 2,
    name: ERoleRequest.TZadmin,
  },
  {
    id: 5,
    name: ERoleRequest.EXTERNAL_ENTITY,
  },
]
