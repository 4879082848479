export enum EConcert {
  Id = 'id',
  EventId = 'eventId',
  ImageLink = 'imageLink',
  ArTitle = 'arTitle',
  ArSubtitle = 'arSubtitle',
  KuTitle = 'kuTitle',
  KuSubtitle = 'kuSubtitle',
  ArAddress = 'arAddress',
  KuAddress = 'kuAddress',
  Categories = 'categories',
  State = 'state',
  DateAndTime = 'dateAndTime',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ChartKey = 'chartKey',
  EventKey = 'eventKey',
  ArTitleLike = 'arTitleLike',
  ArSubtitleLike = 'arSubtitleLike',
  MinDateAndTime = 'minDateAndTime',
  MaxDateAndTime = 'maxDateAndTime',
  Stadium = 'stadium',
  StadiumId = 'stadiumId',
  PublishedAt = 'publishedAt',
  ConcertCategory = 'concertCategory',
}

export enum EEventState {
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export type TConcert = {
  [EConcert.Id]: number
  [EConcert.EventId]: number | null
  [EConcert.ImageLink]: string | null
  [EConcert.ArTitle]: string | null
  [EConcert.ArSubtitle]: string | null
  [EConcert.KuTitle]: string | null
  [EConcert.KuSubtitle]: string | null
  [EConcert.ArAddress]: string | null
  [EConcert.KuAddress]: string | null
  [EConcert.Categories]: any[] | null
  [EConcert.State]: EEventState
  [EConcert.DateAndTime]: Date
  [EConcert.CreatedAt]: Date
  [EConcert.UpdatedAt]: Date
  [EConcert.ConcertCategory]: EConcertCategory
}

export enum EConcertCategory {
  CONCERT = 'CONCERT',
  WORKSHOP = 'WORKSHOP',
}
