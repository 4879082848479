import { Box } from '@mui/material'
import {
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  SimpleForm,
  required,
} from 'react-admin'
import { ECostChange } from './types'
import { EValue, EVariant } from '../Variants/types'

export const CostChangesCreate = () => (
  <Create title="Capture a Cost change" redirect="list">
    <SimpleForm sanitizeEmptyValues>
      <Box
        display={{
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <ReferenceInput source={ECostChange.VariantId} reference="variants" perPage={1000}>
          <SelectInput 
            optionText={(record) => `#${record[EVariant.Id]} - ${record[EVariant.Value].map((v: any) => v[EValue.EnName] || v[EValue.EnValue]).join(' | ')}`} 
            validate={required()} 
            fullWidth 
          />
        </ReferenceInput>

        <NumberInput
          source={ECostChange.Cost}
          label="Cost (IQD)"
          min={1}
          validate={[required()]}
          fullWidth
        />

        <DateInput source={ECostChange.DateOfChange} label="Date of change" validate={required()} fullWidth />
         
      </Box>
    </SimpleForm>
  </Create>
)
