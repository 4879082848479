import {
  Edit,
  SimpleForm,
  DateTimeInput,
  ReferenceInput,
  WithRecord,
  SelectInput,
  required,
  ArrayInput,
  TextField,
  NumberInput,
  SimpleFormIterator,
  TextInput,
  minValue,
} from 'react-admin'
import { Box } from '@mui/material'

import { EMatch, ETeam } from './types'
import { EStadium } from '../Stadiums/types'
import { dateTimeZoneFormatters } from '@/services/api/helpers'

export const MatchesEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
          pr={{ xs: 0, sm: '-0.5em' }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.ArTitle} label="Title (Arabic)" fullWidth />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.KuTitle} label="Title (Kurdish)" fullWidth />
            </Box>
          </Box>

          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.ArSubtitle} label="Subtitle (Arabic)" fullWidth />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.KuSubtitle} label="Subtitle (Kurdish)" fullWidth />
            </Box>
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.ArAddress} label="Address (Arabic)" fullWidth />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EMatch.KuAddress} label="Address (Kurdish)" fullWidth />
            </Box>
          </Box>

          <Box mr={{ xs: 0, sm: '0.5em' }}>
            <DateTimeInput source={EMatch.DateAndTime} fullWidth required {...dateTimeZoneFormatters} />
          </Box>
          <Box mr={{ xs: 0, sm: '0.5em' }}>
            <DateTimeInput source={EMatch.PublishedAt} fullWidth {...dateTimeZoneFormatters} />
          </Box>

          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <WithRecord
                render={(record) => (
                  <ReferenceInput source={EMatch.TeamAId} reference="teams" perPage={1000}>
                    <SelectInput
                      label="Team A"
                      optionText={ETeam.ArName}
                      defaultValue={record[EMatch.TeamA][ETeam.Id]}
                      validate={required()}
                      fullWidth
                    />
                  </ReferenceInput>
                )}
              />{' '}
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <WithRecord
                render={(record) => (
                  <ReferenceInput source={EMatch.TeamBId} reference="teams" required perPage={1000}>
                    <SelectInput
                      label="Team B"
                      optionText={ETeam.ArName}
                      defaultValue={record[EMatch.TeamB][ETeam.Id]}
                      validate={required()}
                      fullWidth
                    />
                  </ReferenceInput>
                )}
              />{' '}
            </Box>
          </Box>

          <Box mr={{ xs: 0, sm: '0.5em' }}>
            <WithRecord
              render={(record) => (
                <ReferenceInput source={EMatch.StadiumId} reference="stadiums" required perPage={1000}>
                  <SelectInput
                    label="Stadium"
                    optionText={EStadium.ArName}
                    defaultValue={record[EMatch.Stadium][EStadium.Id]}
                    validate={required()}
                    fullWidth
                  />
                </ReferenceInput>
              )}
            />
          </Box>

          <Box mr={{ xs: 0, sm: '0.5em' }}>
            <ArrayInput source={EMatch.Categories}>
              <SimpleFormIterator disableClear disableRemove disableAdd disableReordering fullWidth>
                <TextField source="label" />
                <NumberInput source="price" validate={minValue(1, 'Price must be equal or higher than 1')} />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  )
}
