import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'

import { API_AUTH_BASE_URL, CUSTOM_JSON_HEADERS } from './consts'
import { getAccessToken, getRefreshToken, setAccessToken } from './helpers'
import { getNewAccessToken } from './methods/getNewAccessToken'

export const axiosInstanceRaw: AxiosInstance = axios.create({
  baseURL: API_AUTH_BASE_URL,
  headers: CUSTOM_JSON_HEADERS,
})

const axiosInstanceGenerator = (baseUrl?: string) => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: baseUrl,
    headers: CUSTOM_JSON_HEADERS,
  })

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = getAccessToken()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 401) {
        const refreshToken = getRefreshToken()

        if (refreshToken) {
          const { accessToken } = await getNewAccessToken(refreshToken)

          if (accessToken) {
            setAccessToken(accessToken)

            return axiosInstance(error.config)
          }
        }
        return Promise.reject(error)
      }

      return Promise.reject(error)
    },
  )

  return axiosInstance
}

export const axiosAuthInstance = axiosInstanceGenerator(API_AUTH_BASE_URL)

axiosRetry(axiosAuthInstance, { retries: 3 })
