import { Box } from '@mui/material'
import { Create, SimpleForm, TextInput } from 'react-admin'
import { EStadium } from './types'

export const StadiumCreate = () => {
  return (
    <Create title="Create new Stadium" redirect="list">
      <SimpleForm>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EStadium.ArName} fullWidth required />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EStadium.KuName} fullWidth required />
            </Box>
          </Box>

          <TextInput source={EStadium.ChartKey} fullWidth required />
        </Box>
      </SimpleForm>
    </Create>
  )
}
