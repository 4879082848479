import { Create, SimpleForm, TextInput, useCreate, useRedirect } from 'react-admin'
import { Box } from '@mui/material'
import { ETeam, AWSUrlType } from './types'
import { ImageInputField } from '@/components/ImageInputField'
import { putAWSImageFIle } from '@/services/api/methods/putAWSImageFile'
import { TEAMS } from '@/services/api/routes'

export const TeamsCreate = () => {
  const [create] = useCreate()
  const redirect = useRedirect()

  const handleSubmit = (values: {
    logo?: {
      file: File
      imageUploadUrl: AWSUrlType
    }
  }) => {
    const { imageUploadUrl, file } = values.logo || {}
    if (imageUploadUrl && file) {
      putAWSImageFIle(imageUploadUrl.uploadUrl, file)
    }
    create('teams', { data: { ...values, logo: imageUploadUrl?.fileUrl } })
    setTimeout(() => redirect(TEAMS()), 2000)
  }

  return (
    <Create title="Create new Team" redirect="list">
      <SimpleForm onSubmit={handleSubmit}>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={ETeam.ArName} fullWidth required />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={ETeam.KuName} fullWidth required />
            </Box>
          </Box>

          <ImageInputField />
        </Box>
      </SimpleForm>
    </Create>
  )
}
