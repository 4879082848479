import { NO_WHITESPACE } from '@/consts/regexp'
import { minLengthTrimmed, requiredTrimmed } from '@/utils/validation'
import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  regex,
  required,
} from 'react-admin'
import { EBrand } from '../Brands/types'
import { providerChoices, typeChoices } from './consts'
import { EProduct } from './types'

export const ProductsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput
        source={EProduct.EnName}
        label="English Name"
        validate={[requiredTrimmed, minLengthTrimmed(1)]}
        required
        fullWidth
      />
      <TextInput
        source={EProduct.ArName}
        label="Arabic Name"
        validate={[requiredTrimmed, minLengthTrimmed(1)]}
        required
        fullWidth
      />

      <SelectInput source={EProduct.Type} choices={typeChoices} validate={required()} optionValue="name" fullWidth />
      <SelectInput
        source={EProduct.Provider}
        choices={providerChoices}
        validate={required()}
        optionValue="name"
        fullWidth
      />

      <TextInput
        source={EProduct.Logo}
        label="Logo Url"
        validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
        required
        fullWidth
      />

      <ReferenceInput source={EProduct.BrandId} reference="brands" perPage={1000}>
        <SelectInput
          optionText={(record) => `${record[EBrand.EnName]} - #${record.id}`}
          validate={required()}
          fullWidth
        />
      </ReferenceInput>

      <TextInput source={EProduct.EnRedeemInstructions} label="English Redeem Instructions" fullWidth />
      <TextInput source={EProduct.ArRedeemInstructions} label="Arabic Redeem Instructions" fullWidth />
      <BooleanInput source={EProduct.IsActive} fullWidth />
      <NumberInput
        source={EProduct.Order}
        label="Order"
        validate={(value) => (Number.isInteger(value) ? undefined : 'Order must be an integer')}
        fullWidth
        required
      />
    </SimpleForm>
  </Edit>
)
