export enum ESubCategory {
  Id = 'id',
  EnName = 'enName',
  ArName = 'arName',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MainCategoryId = 'mainCategoryId',
  IsActive = 'isActive',
  Order = 'order',
}

export type TSubCategory = {
  [ESubCategory.Id]: number
  [ESubCategory.EnName]: string
  [ESubCategory.ArName]: string
  [ESubCategory.CreatedAt]: Date
  [ESubCategory.UpdatedAt]: Date
  [ESubCategory.MainCategoryId]: number
  [ESubCategory.IsActive]: boolean
  [ESubCategory.Order]: number
}
