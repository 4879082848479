export enum ESettlement {
  Id = 'id',
  AdminID = 'adminID',
  Amount = 'amount',
  SMS = 'sms',
  Motive = 'motive',
  Comment = 'comment',
  Picture = 'picture',
}

export type TSettlement = {
  [ESettlement.Id]: string
  [ESettlement.AdminID]: string
  [ESettlement.Amount]: number
  [ESettlement.SMS]: string
  [ESettlement.Motive]: string
  [ESettlement.Comment]: string
  [ESettlement.Picture]: string
}
