import { NO_WHITESPACE } from '@/consts/regexp'
import { Box } from '@mui/material'
import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput, regex } from 'react-admin'
import { EMainCategory } from './types'

export const MainCategoriesEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm sanitizeEmptyValues>
      <Box
        display={{
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <TextInput
          source={EMainCategory.EnName}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="English Name"
          required
          fullWidth
        />
        <TextInput
          source={EMainCategory.ArName}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="Arabic Name"
          required
          fullWidth
        />

        <TextInput
          source={EMainCategory.Logo}
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          label="Logo url"
          required
          fullWidth
        />

        <BooleanInput source={EMainCategory.IsActive} fullWidth />

        <NumberInput
          source={EMainCategory.Order}
          label="Order"
          validate={(value) => (Number.isInteger(value) ? undefined : 'Order must be an integer')}
          fullWidth
          required
        />
      </Box>
    </SimpleForm>
  </Edit>
)
