import { useEffect } from 'react'
import { usePermissions, useRedirect } from 'react-admin'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { tokens } from '@/Layout/theme'
import StatBox from '@/components/StatBox'
import BarChart from '@/components/BarChart'
import LineChart from '@/components/LineChart'
import ProgressCircle from '@/components/ProgressCircle'
import EmailIcon from '@mui/icons-material/Email'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import TrafficIcon from '@mui/icons-material/Traffic'
import { ERole, ERoleRequest } from '@/types/data'
import { MERCHANTS_ORDERS, SUPPLIERS_ORDERS, STADIUMS } from '@/services/api/routes'

export const Dashboard = () => {
  const colors = tokens()
  const { permissions } = usePermissions()
  const redirect = useRedirect()

  const isAdmin = [ERole.Admin, ERoleRequest.Admin, ERole.Superadmin].includes(permissions)
  const isMerchant = [ERole.Merchant, ERoleRequest.Merchant].includes(permissions)

  useEffect(() => {
    if (!isAdmin) {
      if (permissions === ERoleRequest.TZadmin) {
        redirect(STADIUMS())
      } else {
        redirect(isMerchant ? MERCHANTS_ORDERS() : SUPPLIERS_ORDERS())
      }
    }
  }, [])

  return !isAdmin ? (
    <div />
  ) : (
    <Card sx={{ padding: '0 24px 20px' }}>
      <CardContent sx={{ color: colors.primary[800], fontSize: '24px' }}>Welcome to Digital Zone Dashboard</CardContent>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="4px"
          sx={{ backgroundColor: colors.neutral[800] }}
        >
          <StatBox
            title="12,361"
            subtitle="Emails Sent"
            progress={0.75}
            increase="+14%"
            icon={<EmailIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="4px"
          sx={{ backgroundColor: colors.primary[600] }}
        >
          <StatBox
            title="431,225"
            subtitle="Sales Obtained"
            progress={0.5}
            increase="+21%"
            icon={<PointOfSaleIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
            progressColors={{
              inner: colors.primary[600],
              background: colors.cyanosed[300],
              main: colors.neutral[800],
            }}
          />
        </Box>
        <Box
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="4px"
          sx={{ backgroundColor: colors.neutral[800] }}
        >
          <StatBox
            title="32,441"
            subtitle="New Clients"
            progress={0.3}
            increase="+5%"
            icon={<PersonAddIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="4px"
          sx={{ backgroundColor: colors.primary[600] }}
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress={0.8}
            increase="+43%"
            icon={<TrafficIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
            progressColors={{
              inner: colors.primary[600],
              background: colors.cyanosed[300],
              main: colors.neutral[800],
            }}
          />
        </Box>

        {/* ROW 2 */}
        <Box gridColumn="span 8" gridRow="span 2" borderRadius="4px" sx={{ backgroundColor: colors.neutral[800] }}>
          <Box mt="25px" p="0 30px" display="flex " justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.primary[100]}>
                Revenue Generated
              </Typography>
              <Typography variant="h3" fontWeight="bold" color={colors.primary[500]}>
                $59,342.32
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          overflow="auto"
          borderRadius="4px"
          sx={{ backgroundColor: colors.primary[600] }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Typography color={colors.primary[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          sx={{ backgroundColor: colors.neutral[800] }}
          borderRadius="4px"
          p="30px"
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[100]}>
            Campaign
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
            <ProgressCircle size="125" progress={0.75} />
            <Typography variant="h5" color={colors.primary[100]} sx={{ mt: '15px' }}>
              $48,352 revenue generated
            </Typography>
            <Typography color={colors.primary[300]}>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box gridColumn="span 6" gridRow="span 2" borderRadius="4px" sx={{ backgroundColor: colors.neutral[800] }}>
          <Typography variant="h5" fontWeight="600" sx={{ padding: '30px 30px 0 30px' }} color={colors.primary[100]}>
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
