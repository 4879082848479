import { EVariant, TValues } from './types'
import { EType } from '@/modules/Products/types'

export const valuesMapper = (values: TValues) => {
  const mappedValues = {
    ...values,
    [EVariant.Value]:
      values[EVariant.Type] === EType.VOUCHER ? values[EVariant.VoucherValue] : values[EVariant.BundleValue],
  }

  delete mappedValues[EVariant.Type]
  delete mappedValues[EVariant.BundleValue]
  delete mappedValues[EVariant.VoucherValue]
  return mappedValues
}
