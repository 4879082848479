import { BooleanInput, Edit, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin'
import { ETicket } from './types'

const CustomToolbar = () => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
  </Toolbar>
)

export const TicketsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="list">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source={ETicket.Sector} fullWidth />
      <BooleanInput source={ETicket.Used} />
      <TextInput source={ETicket.HolderName} fullWidth />
      <TextInput source={ETicket.HolderPhone} fullWidth />
    </SimpleForm>
  </Edit>
)
