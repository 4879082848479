import {
  ArrayField,
  List,
  Datagrid,
  TextField,
  NumberField,
  ImageField,
  WrapperField,
  BulkExportButton,
  BulkDeleteButton,
  BooleanField,
} from 'react-admin'

import { Box } from '@mui/material'
import { EMainCategory } from '@/modules/MainCategories/types'
import { ESortOrder } from '@/types/data'
import { ESubCategory } from '@/modules/SubCategories/types'

export const MainCategoriesList = () => (
  <List sort={{ field: EMainCategory.Order, order: ESortOrder.Asc }}>
    <Datagrid
      rowClick="edit"
      bulkActionButtons={
        <>
          <BulkExportButton />
          <BulkDeleteButton />
        </>
      }
    >
      <NumberField source={EMainCategory.Id} textAlign="left" />

      <WrapperField label="Category" sortBy={EMainCategory.EnName}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ImageField
            source={EMainCategory.Logo}
            sortable={false}
            sx={{
              '& img': {
                maxWidth: 50,
                maxHeight: 50,
                objectFit: 'cover !important',
                borderRadius: '50%',
              },
              display: 'flex',
              marginRight: '8px',
            }}
          />
          <TextField source={EMainCategory.EnName} label="English Name" />
        </Box>
      </WrapperField>
      <TextField source={EMainCategory.ArName} label="Arabic Name" sortBy="arName" />
      <BooleanField source={EMainCategory.IsActive} sortable={false} />
      <NumberField source={EMainCategory.Order} sortable />

      <ArrayField
        source={EMainCategory.SubCategories}
        sortable={false}
        sort={{ field: ESubCategory.Order, order: ESortOrder.Asc }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source={ESubCategory.Id} sortable={false} />
          <TextField source={ESubCategory.EnName} label="English Name" sortable={false} />
          <TextField source={ESubCategory.ArName} label="Arabic Name" sortable={false} />
          <NumberField source={ESubCategory.Order} sortable={false} />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
)
