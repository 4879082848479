import axios from 'axios'
import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { getAccessToken } from '@/services/api//helpers'

export const postCostChangeUpload = async (file: File, notify: (msg?: string, typeObj?: object) => void) => {
  const formdata = new FormData()
  const accessToken = getAccessToken()
  formdata.append('file', file, 'file')

  try {
    await axios({
      method: 'post',
      url: `${API_AUTH_BASE_URL}/dashboard/cost-changes/bulk`,
      data: formdata,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` },
    })
    notify('Cost change updated successfully', { type: 'success' })
  } catch (error: any) {
    const errorsData = error?.response?.data
    notify(
      `File sending error: ${errorsData?.message}.

      ${errorsData?.errors
        ?.slice(0, 6)
        .map((e: any) => JSON.stringify(e?.constraints))
        .join(',\n')}`,
      { type: 'error', autoHideDuration: 7000, multiLine: true },
    )
    throw new Error((error as Error).message)
  }
}
