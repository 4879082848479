import { ESubCategory } from '../SubCategories/types'

export enum EMainCategory {
  Id = 'id',
  EnName = 'enName',
  ArName = 'arName',
  Logo = 'logo',
  SubCategories = 'subCategories',
  IsActive = 'isActive',
  Order = 'order',
}

export type TSubCategory = {
  [ESubCategory.Id]: number
  [ESubCategory.EnName]: string
  [ESubCategory.ArName]: string
  [ESubCategory.Order]: number
}

export type TMainCategory = {
  [EMainCategory.Id]: number
  [EMainCategory.EnName]: string
  [EMainCategory.ArName]: string
  [EMainCategory.Logo]: string
  [EMainCategory.SubCategories]: TSubCategory[]
  [EMainCategory.IsActive]: boolean
  [EMainCategory.Order]: number
}
