import { regex, required } from 'react-admin'
import { EPlatformType, EPromoCodeType, EValueType } from './types'
import { EValue, EVariant, TProduct } from '@/modules/Variants/types'
import { EProduct } from '../Products/types'
import { EBrand } from '../Brands/types'
import { EConcertCategory } from '../Concerts/types'

export const choices = [
  {
    id: 1,
    name: EValueType.FIXED,
  },
  {
    id: 2,
    name: EValueType.RATE,
  },
]

export const promoCodeChoices = [
  {
    id: 1,
    name: EPromoCodeType.BRAND,
  },
  {
    id: 2,
    name: EPromoCodeType.PRODUCT,
  },
  {
    id: 3,
    name: EPromoCodeType.VARIANT,
  },
]

export const concertCategoryChoices = [
  {
    id: 1,
    name: EConcertCategory.CONCERT,
  },
  {
    id: 2,
    name: EConcertCategory.WORKSHOP,
  },
]

export const platformChoises = [
  {
    id: 1,
    name: EPlatformType.CONSUMER_APP,
  },
  {
    id: 2,
    name: EPlatformType.QI_SERVICES_APP,
  },
  {
    id: 3,
    name: EPlatformType.SUPER_QI_APP,
  },
]

export const validatePhone = [
  required(),
  regex(/^\+\d{8,14}$/, 'Provide a valid phone number with a country code, 8-14 characters long'),
]

export const optionText = (record: {
  [EVariant.Id]: string
  [EVariant.Product]: TProduct
  [EVariant.Value]: [
    {
      [EValue.EnName]?: string
      [EValue.EnValue]?: string
      [EValue.ArName]?: string
      [EValue.ArValue]?: string
    },
  ]
}) => {
  const value = record[EVariant.Value][0]
  const readableEnValue = value[EValue.EnValue] || value[EValue.EnName]
  const readableArValue = value[EValue.ArValue] || value[EValue.ArName]

  return `Id: ${record[EVariant.Id]} |  [VALUE] "${readableEnValue}" - "${readableArValue}" | [BRAND] "${
    record[EVariant.Product][EProduct.Brand][EBrand.EnName]
  }" | [PRODUCT] "${record[EVariant.Product][EProduct.EnName]}"
  `
}

export const optionTextBrandsOrProducts = (record: {
  [EBrand.Id]: string
  [EBrand.EnName]?: string
  [EBrand.ArName]?: string
}) => {
  return `Id: ${record[EBrand.Id]} |  "${record[EBrand.ArName]}" | "${record[EBrand.EnName]}"
  `
}
