import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BulkExportButton,
  WithRecord,
  ArrayField,
  SingleFieldList,
  DateInput,
  ReferenceInput,
  SelectInput,
  Confirm,
  TopToolbar,
  Button,
} from 'react-admin'
import { ECostChange } from '@/modules/CostChanges/types'
import { EValue, EVariant } from '@/modules/Variants/types'
import { EAdmin } from '@/modules/Admins/types'
import { tokens } from '@/Layout/theme'
import { useState } from 'react'
import { ImportCSVModalCostChange } from '@/components/ImportCSVModalCostChange'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import FileUploadIcon from '@mui/icons-material/UploadFile'

const costChangesFilters = [
  <ReferenceInput source={ECostChange.VariantId} reference={'variants'} perPage={1000} key={1} label="Variant" alwaysOn>
    <SelectInput optionText={EVariant.Id} label="Variant" alwaysOn />
  </ReferenceInput>,
  <DateInput source={ECostChange.DateOfChange} alwaysOn key={2} label="Search (Date Of Change)" />,
  
]

export const CostChangesList = () => {
  const [open, setOpen] = useState(false)
  const colors = tokens()
  const handleCloseModal = () => setOpen(false)
  const handleConfirm = () => setOpen(false);

  const CostChangeActions = () => (
    <TopToolbar>
    
      <Button onClick={() => setOpen(true)} label="Import Cost Changes from .csv">
        <FileUploadIcon />
      </Button>
    </TopToolbar>
  )

  return (
    <List filters={costChangesFilters} actions={<CostChangeActions />}>
      <Confirm
        isOpen={open}
        title="Upload CSV"
        content={<ImportCSVModalCostChange handleClose={handleCloseModal} />}
        cancel=""
        confirm="Close"
        ConfirmIcon={() => <ErrorOutlinedIcon />}
        CancelIcon={() => null}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
      />
      <Datagrid bulkActionButtons={<BulkExportButton />}>
        <NumberField source={ECostChange.Id} textAlign="left" />
        <WithRecord
          label="Variant"
          render={() => (
            <ArrayField source={`${ECostChange.Variant}.${EVariant.Value}`} sortable={false}>
              <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    sx={{
                      color: colors.accent.emerald,
                    }}
                    source={EValue.EnValue}
                  />
                  <TextField
                    sx={{
                      color: colors.accent.pink,
                    }}
                    source={EValue.ArValue}
                  />
                </div>
              </SingleFieldList>
            </ArrayField>
          )}
        />
        <NumberField source={ECostChange.Cost} />
        <DateField source={ECostChange.DateOfChange} />
        <NumberField source={ECostChange.Version} />
        <TextField source={`${ECostChange.CreatedBy}.${EAdmin.Username}`} label="Created By" />
        <DateField source={ECostChange.CreatedAt} />
      </Datagrid>
    </List>
  )
}
