import { TProduct } from '../Products/types'
import { TPromocode } from '../Promocodes/types'

export enum EOrder {
  Id = 'id',
  Status = 'status',
  PromoCode = 'promoCode',
  TransactionId = 'transactionId',
  CreatedAt = 'createdAt',
  PaymentMethod = 'paymentMethod',
  Consumer = 'consumer',
  TotalPrice = 'totalPrice',
  Product = 'product',
  UserIdLike = 'userIdLike',
  UserPhoneNumberLike = 'userPhoneNumberLike',
  MerchantUserId = 'merchantUserId',
  TransactionIdLike = 'transactionIdLike',
  ProductId = 'productId',
  BrandId = 'brandId',
  VariantId = 'variantId'
}

export enum EStatus {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export enum EConsumer {
  Id = 'id',
  Phone = 'phone',
}

type TConsumer = {
  id: string
  phone: string
}

type TStatus = [EStatus.PENDING] | [EStatus.CANCELLED] | [EStatus.FAILURE] | [EStatus.SUCCESS]

export type TOrder = {
  [EOrder.Id]: number
  [EOrder.PromoCode]: TPromocode
  [EOrder.Status]: TStatus
  [EOrder.TransactionId]: string
  [EOrder.CreatedAt]: Date
  [EOrder.PaymentMethod]: string
  [EOrder.Consumer]: TConsumer
  [EOrder.TotalPrice]: number
  [EOrder.Product]: TProduct
}
