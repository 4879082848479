export enum ETicket {
  Id = 'id',
  UserId = 'userId',
  MatchId = 'matchId',
  Sector = 'sector',
  Price = 'price',
  Used = 'used',
  HolderName = 'holderName',
  HolderPhone = 'holderPhone',
  TicketOrderId = 'ticketOrderId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  SectorLike = 'sectorLike',
  HolderNameLike = 'holderNameLike',
  HolderPhoneLike = 'holderPhoneLike',
  MinPrice = 'minPrice',
  MaxPrice = 'maxPrice',
}

export type TTicket = {
  [ETicket.Id]: number
  [ETicket.UserId]: string
  [ETicket.MatchId]: number
  [ETicket.Sector]: string
  [ETicket.Price]: number
  [ETicket.Used]: boolean
  [ETicket.HolderName]: string
  [ETicket.HolderPhone]: string
  [ETicket.TicketOrderId]: number
  [ETicket.CreatedAt]: Date
  [ETicket.UpdatedAt]: Date
}
