import { axiosAuthInstance } from '../axios'
import { AWS_UPLOAD_URL } from '../routes'

export const getAWSImageUrl = async (key: string) => {
  try {
    const { data } = await axiosAuthInstance.get(AWS_UPLOAD_URL(), {
      params: {
        key,
      },
    })

    return data
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
