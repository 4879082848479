const responseMapper = (data: { items: any[] }) => {
  return { data: data.items, total: data.items.length }
}

export const getExistingRoles = async () => {
  const { data } = await Promise.resolve({
    data: { items: [] },
  })

  return responseMapper(data)
}
