import { GetListParams } from 'react-admin'

import { settlementsMock } from '@/modules/Settlement/mocks'
import { TSettlement } from '@/modules/Settlement/types'

const responseMapper = (data: TSettlement[]) => {
  return { data, total: data.length }
}

// TODO: remove mock, add API request

export const getAllSettlement = async (params: GetListParams) => {
  const { data } = await Promise.resolve<{ data: TSettlement[] }>({
    data: settlementsMock,
  })

  return responseMapper(data)
}
