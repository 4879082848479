export enum EResource {
  Brands = 'brands',
  Categories = 'categories',
  Fees = 'fees',
  Orders = 'orders',
  Posts = 'posts',
  POSProducts = 'posProducts',
  Products = 'products',
  Promocodes = 'promo-codes',
  Roles = 'roles',
  Settlement = 'settlement',
  Users = 'users',
  Admins = 'admins',
  Variants = 'variants',
  CostChanges = 'cost-changes',
  Events = 'events',
  Stadiums = 'stadiums',
  Teams = 'teams',
  Seasons = 'seasons',
  Rounds = 'rounds',
  Matches = 'matches',
  Concerts = 'concerts',
  Tickets = 'tickets',
  TicketOrders = 'ticket-orders',
  SubCategories = 'sub-categories',
  MainCategories = 'main-categories',
  MerchantsOrders = 'merchants/orders',
  SuppliersOrders = 'suppliers/orders',
  Settings = 'settings',
  AllowedPages = 'allowed-pages',
}
