import { useState, useEffect } from 'react'
import { axiosAuthInstance } from '../../services/api/axios'
import AllowedPageItem from '../AllowedPageItem/AllowedPageItem'
import { useParams } from 'react-router-dom'
import { API_AUTH_BASE_URL } from '@/services/api/consts'

const AllowedPages = () => {
  const params = useParams()
  const id = params['*']
  const [responseData, setResponseData] = useState<string[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosAuthInstance.get(`${API_AUTH_BASE_URL}/dashboard/pages/single/${id}`)
        setResponseData([response.data.iFrameURL])
      } catch (error) {
        console.error('----Error fetching data:', error)
      }
    }

    fetchData()
  }, [id])

  useEffect(() => {
    console.log('----responseData', responseData)
  }, [responseData])

  return (
    <div>
      {responseData.map((pageData, index) => (
        <AllowedPageItem key={index} href={pageData} />
      ))}
    </div>
  )
}

export default AllowedPages
