import { useEffect, useState } from 'react'
import { ImageInput, ImageField, required } from 'react-admin'
import { AWSUrlType, ETeam } from '@/modules/Teams/types'
import { getAWSImageUrl } from '@/services/api/methods/getAWSImageUrl'

export const ImageInputField = ({ isEdit }: { isEdit?: boolean }) => {
  const [imageUploadUrl, setImageUploadUrl] = useState<AWSUrlType>()
  const [isNewFile, setIsNewFile] = useState(false)

  const fetchData = async () => {
    const resp = await getAWSImageUrl('teams')

    if (!resp) return
    setImageUploadUrl(resp)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const parsedValue = (file: File) => {
    if (!file) return
    return { file, src: URL.createObjectURL(file), imageUploadUrl }
  }

  const formattedValue = (file: File | { src: File }) => {
    return isEdit && !isNewFile ? { src: file } : { ...file }
  }

  const handleDrop = () => {
    setIsNewFile(true)
  }

  return (
    <ImageInput
      source={ETeam.Logo}
      label="Logo"
      validate={required()}
      parse={parsedValue}
      format={formattedValue}
      options={{ onDropAccepted: handleDrop }}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  )
}
