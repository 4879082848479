import { EFee, ECard, TFee } from './types'

export const feesMock: TFee[] = [
  {
    [EFee.Id]: '452',
    [EFee.Card]: {
      [ECard.Name]: 'Test1',
      [ECard.Url]: 'https://i.ibb.co/1ncbV5d/3-1.png',
    },
    [EFee.Price]: 250,
    [EFee.TasdidFees]: 0,
    [EFee.QiFees]: 0.02,
    [EFee.APSFees]: 0,
    [EFee.HawalaFees]: 0,
    [EFee.WalletFees]: 0,
    [EFee.CardsType]: 'Foursan Al Saree3',
    [EFee.Condition]: 'available',
  },
  {
    [EFee.Id]: '451',
    [EFee.Card]: {
      [ECard.Name]: 'user2',
      [ECard.Url]: 'https://i.ibb.co/1ncbV5d/3-1.png',
    },
    [EFee.Price]: 35.76,
    [EFee.TasdidFees]: 0,
    [EFee.QiFees]: 0.02,
    [EFee.APSFees]: 0,
    [EFee.HawalaFees]: 0,
    [EFee.WalletFees]: 0,
    [EFee.CardsType]: 'Foursan Al Saree3',
    [EFee.Condition]: 'available',
  },
  {
    [EFee.Id]: '457',
    [EFee.Card]: {
      [ECard.Name]: 'Diamonds 2333',
      [ECard.Url]: 'https://i.ibb.co/xC4wFyd/L9SUEMRQ.png',
    },
    [EFee.Price]: 250,
    [EFee.TasdidFees]: 0,
    [EFee.QiFees]: 22,
    [EFee.APSFees]: 1,
    [EFee.HawalaFees]: 0,
    [EFee.WalletFees]: 0,
    [EFee.CardsType]: 'Yalla Ludo',
    [EFee.Condition]: 'unavailable',
  },
  {
    [EFee.Id]: '411',
    [EFee.Card]: {
      [ECard.Name]: 'PUBG 2000',
      [ECard.Url]: 'https://i.ibb.co/1ncbV5d/3-1.png',
    },
    [EFee.Price]: 257,
    [EFee.TasdidFees]: 0,
    [EFee.QiFees]: 0.02,
    [EFee.APSFees]: 0,
    [EFee.HawalaFees]: 0,
    [EFee.WalletFees]: 0,
    [EFee.CardsType]: 'PUBG',
    [EFee.Condition]: 'available',
  },
  {
    [EFee.Id]: '472',
    [EFee.Card]: {
      [ECard.Name]: 'Test1',
      [ECard.Url]: 'https://i.ibb.co/1ncbV5d/3-1.png',
    },
    [EFee.Price]: 250,
    [EFee.TasdidFees]: 0,
    [EFee.QiFees]: 0.02,
    [EFee.APSFees]: 0,
    [EFee.HawalaFees]: 0,
    [EFee.WalletFees]: 0,
    [EFee.CardsType]: 'Foursan Al Saree3',
    [EFee.Condition]: 'available',
  },
]
