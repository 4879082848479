import {
  List,
  Datagrid,
  TextField,
  NumberField,
  NumberInput,
  DateField,
  ChipField,
  required,
  SelectInput,
  BulkExportButton,
  TopToolbar,
  FilterButton,
  DateInput,
} from 'react-admin'
import { tokens } from '@/Layout/theme'

import { EOrder, EStatus } from '@/modules/Suppliers/Orders/types'
import { LabelField } from '@/components/LabelField'
import { EPromocode } from '@/modules/Promocodes/types'
import { EProduct } from '@/modules/Products/types'

export const choices = [
  {
    id: 1,
    name: EStatus.SUCCESS,
  },
  {
    id: 2,
    name: EStatus.PENDING,
  },
  {
    id: 3,
    name: EStatus.CANCELLED,
  },
  {
    id: 4,
    name: EStatus.FAILURE,
  },
]

const formatAfterDate = (value: string) => `${value}T00:00:00`
const formatBeforeDate = (value: string) => `${value}T23:59:59`

const ordersFilters = [
  <NumberInput source="ids" alwaysOn key={1} label="Search (id)" />,
  <SelectInput
    label="Status Filter"
    source={EOrder.Status}
    defaultValue={EStatus.SUCCESS}
    key={EStatus.SUCCESS}
    choices={choices}
    validate={required()}
    optionValue="name"
  />,
  <DateInput source={EOrder.CreatedAtAfter} label="Created At (after)" key={2} parse={formatAfterDate} />,
  <DateInput source={EOrder.CreatedAtBefore} label="Created At (before)" key={3} parse={formatBeforeDate} />,
]

const PostOrdersActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
)

export const SuppliersOrdersList = () => {
  const colors = tokens()

  return (
    <List filters={ordersFilters} actions={<PostOrdersActions />}>
      <Datagrid rowClick={false} bulkActionButtons={<BulkExportButton />}>
        <NumberField source={EOrder.Id} label="ID" textAlign="left" sortable={false} />
        <LabelField source={EOrder.Status} label="Status" sortable={false} />
        <TextField source={`${EOrder.PromoCode}${EPromocode.Id}`} label="Promocode Id" sortable={false} />
        <TextField source={`${EOrder.PromoCode}${EPromocode.Value}`} label="Promocode" sortable={false} />
        <TextField source={EOrder.ToppedUpUser} sortable={false} />
        <TextField source={EOrder.TotalPrice} sortable={false} />

        <ChipField
          sx={{
            backgroundColor: colors.accent.pink,
            color: colors.neutral[0],
          }}
          source={`${EOrder.Product}.${EProduct.EnName}`}
          label="Product"
          sortable={false}
        />
        <DateField source={EOrder.CreatedAt} sortable={false} />
      </Datagrid>
    </List>
  )
}
