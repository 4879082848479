import './style.css'

interface AllowedPageProps {
  href: string
}

const AllowedPageItem = ({ href }: AllowedPageProps) => {
  return (
    <div className="container">
      <iframe className="responsive-iframe" src={href} title="title of the iframe" />
    </div>
  )
}

export default AllowedPageItem
