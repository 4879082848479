import { Box } from '@mui/material'
import { tokens } from '@/Layout/theme'

type TProps = {
  progress: number
  size: string
  inner?: string
  background?: string
  main?: string
}

const ProgressCircle = ({
  progress,
  size,
  inner,
  background,
  main,
}: TProps) => {
  const angle = progress * 360
  const colors = tokens()

  return (
    <Box
      sx={{
        background: `radial-gradient(${
          inner || colors.neutral[800]
        } 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${
          background || colors.accent.violet
        } ${angle}deg 360deg),
            ${main || colors.primary[800]}`,
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  )
}

export default ProgressCircle
