export enum ETeam {
  Id = 'id',
  Logo = 'logo',
  ArName = 'arName',
  KuName = 'kuName',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ArNameLike = 'arNameLike',
  KuNameLike = 'kuNameLike',
}

export type TTeam = {
  [ETeam.Id]: number
  [ETeam.Logo]?: string
  [ETeam.ArName]: string
  [ETeam.KuName]: string
  [ETeam.CreatedAt]: Date
  [ETeam.UpdatedAt]: Date
}

export type TCreateTeam = {
  createdId: string
}

export type AWSUrlType = {
  uploadUrl: string
  fileUrl: string
}
