import {
  CreateParams,
  DeleteParams,
  GetListParams,
  GetOneParams,
  GetManyParams,
  UpdateParams,
  DeleteManyParams,
  fetchUtils,
} from 'react-admin'

import { getExistingRoles } from '@/services/api/methods/getExistingRoles'
import { getAllFees } from '@/services/api/methods/getAllFees'
import { getAllSettlement } from '@/services/api/methods/getAllSettlement'

import { EResource } from './types'
import simpleRestProvider from 'ra-data-simple-rest'

import {
  getCustomList,
  getCustomOne,
  customCreate,
  customDelete,
  customUpdate,
  getCustomMany,
  adminsUpdate,
} from '@/services/api/helpers'
import { API_AUTH_BASE_URL, CUSTOM_JSON_HEADERS } from '@/services/api/consts'

const fetchJson = (url: string, options: fetchUtils.Options = {}) => {
  const customHeaders = (options.headers || new Headers(CUSTOM_JSON_HEADERS)) as Headers
  options.headers = customHeaders
  return fetchUtils.fetchJson(url, options)
}

const baseDataProvider = simpleRestProvider(`${API_AUTH_BASE_URL}/dashboard`, fetchJson)

export const dataProvider = {
  ...baseDataProvider,
  getList: (resource: string, params: GetListParams) => {
    switch (resource) {
      case EResource.Roles:
        return getExistingRoles()
      case EResource.Fees:
        return getAllFees(params)
      case EResource.Settlement:
        return getAllSettlement(params)
      default:
        return getCustomList(resource, params)
    }
  },
  getMany: (resource: string, params: GetManyParams) => {
    switch (resource) {
      default:
        return getCustomMany(resource, params)
    }
  },
  getOne: (resource: string, params: GetOneParams) => {
    switch (resource) {
      default:
        return getCustomOne(resource, params)
    }
  },
  create: (resource: string, params: CreateParams) => {
    switch (resource) {
      default:
        return customCreate(resource, params)
    }
  },
  update: (resource: string, params: UpdateParams) => {
    switch (resource) {
      case EResource.Admins:
        return adminsUpdate(resource, params)
      default:
        return customUpdate(resource, params)
    }
  },
  delete: (resource: string, params: DeleteParams) => {
    switch (resource) {
      default:
        return customDelete(resource, params)
    }
  },
  deleteMany: (resource: string, params: DeleteManyParams) => {
    switch (resource) {
      default:
        return Promise.all(params.ids.map((id) => customDelete(resource, { id }))).then((responses) => ({
          data: responses.map(({ data }) => data.id),
        }))
    }
  },
}
