import { axiosAuthInstance } from '../axios'

export const apiAuthentication = async (credentials: { username: string; password: string }) => {
  try {
    const { data } = await axiosAuthInstance.post('/dashboard/auth/sign-in', {
      ...credentials,
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}
