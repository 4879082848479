import { useState } from 'react'
import { useTranslate, useSidebarState } from 'react-admin'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { List, ListItem, ListItemText, Collapse } from '@mui/material'
import { tokens } from '@/Layout/theme'

export type SubMenuProps = {
  children?: React.ReactNode
  isDropdownOpen?: boolean
  leftIcon?: React.ReactElement
  primaryText: string
}

export const SubMenu = (props: SubMenuProps) => {
  const { isDropdownOpen = false, primaryText, leftIcon, children } = props
  const translate = useTranslate()
  const [open] = useSidebarState()
  const [isOpen, setIsOpen] = useState(isDropdownOpen)
  const colors = tokens()

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <ListItem
        dense
        onClick={handleToggle}
        sx={{
          paddingLeft: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
          borderRight: `1px solid ${colors.cyanosed[200]}`,
          cursor: 'pointer',
        }}
      >
        {isOpen ? <ExpandLessIcon /> : leftIcon}
        <ListItemText
          inset
          disableTypography
          primary={translate(primaryText)}
          sx={{
            paddingLeft: 2.5,
            fontSize: '14px',
            fontFamily: 'Noto Sans',
            color: colors.cyanosed[700],
          }}
        />
        {isOpen ? null : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={
            open
              ? {
                  paddingLeft: '25px',
                  transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
              : {
                  paddingLeft: 0,
                  transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
          }
        >
          {children}
        </List>
      </Collapse>
    </>
  )
}

export default SubMenu
