import { List, Datagrid, TextField, NumberField, DateField, SearchInput } from 'react-admin'

import { EStadium } from '@/modules/Stadiums/types'
import { ESortOrder } from '@/types/data'

const stadiumsFilters = [
  <SearchInput source={EStadium.ArNameLike} alwaysOn key={1} placeholder="Search (Arabic name)" />,
  <SearchInput source={EStadium.KuNameLike} alwaysOn key={2} placeholder="Search (Kurdish name)" />,
  <SearchInput source={EStadium.ChartKeyLike} alwaysOn key={3} placeholder="Search (Chart key)" />,
]

export const StadiumsList = () => (
  <List filters={stadiumsFilters} sort={{ field: EStadium.ArName, order: ESortOrder.Asc }}>
    <Datagrid rowClick="edit">
      <NumberField source={EStadium.Id} textAlign="left" sortable />
      <TextField source={EStadium.ArName} label="Arabic Name" sortable />
      <TextField source={EStadium.KuName} label="Ku Name" sortable />
      <TextField source={EStadium.ChartKey} label="Chart Key" sortable />
      <DateField source={EStadium.CreatedAt} sortable />
    </Datagrid>
  </List>
)
