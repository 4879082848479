import {
  List,
  Datagrid,
  TextField,
  ImageField,
  NumberField,
  WithRecord,
  WrapperField,
  BooleanField,
  ReferenceField,
  Button,
  Identifier,
  useRedirect,
  BulkExportButton,
  BulkDeleteButton,
  useRefresh,
  ReferenceInput,
  SelectInput,
  DateField,
} from 'react-admin'

import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined'
import { LabelField } from '@/components/LabelField'
import { Box } from '@mui/material'
import { EBrand } from '@/modules/Brands/types'
import { ESubCategory } from '@/modules/SubCategories/types'
import { tokens } from '@/Layout/theme'
import { PRODUCTS } from '@/services/api/routes'

export const BrandsList = () => {
  const colors = tokens()
  const redirect = useRedirect()
  const refresh = useRefresh()

  const handleRedirect =
    ({ id }: { id: Identifier }) =>
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      redirect(
        'list',
        encodeURI(
          `${PRODUCTS()}?displayedFilters={"brandId":true}&filter={"brandId":"${id}"}&order=ASC&page=1&perPage=10&sort=id`,
        ),
      )
    }

  const brandFilters = [
    <ReferenceInput source={EBrand.Ids} reference={'brands'} perPage={1000} key={1} label="Brand Name" alwaysOn>
      <SelectInput optionText="enName" label="Brand Name" />
    </ReferenceInput>,
  ]
  return (
    <List filters={brandFilters}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          <>
            <BulkExportButton />
            <BulkDeleteButton mutationOptions={{ onMutate: refresh }} />
          </>
        }
      >
        <NumberField source={EBrand.Id} textAlign="left" />

        <WrapperField label="Brand" sortBy={EBrand.EnName}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ImageField
              source={EBrand.Logo}
              sx={{
                '& img': {
                  maxWidth: 50,
                  maxHeight: 50,
                  objectFit: 'cover !important',
                  borderRadius: '50%',
                },
                display: 'flex',
                marginRight: '8px',
              }}
            />
            <TextField source={EBrand.EnName} label="English Name" />
          </Box>
        </WrapperField>

        <TextField source={EBrand.ArName} label="Arabic Name" sortBy="arName" />

        <TextField source={EBrand.ArName} label="Arabic Name" sortBy="arName" />
        <NumberField source={EBrand.MarketPlaceCount} label="Activated in Marketplaces" textAlign="left" sortable />
        <NumberField source={EBrand.Order} textAlign="left" sortable />

        <LabelField source={EBrand.IsActive} label="IsActive" sortable={false} />
        <ReferenceField source={EBrand.SubCategoryId} reference="sub-categories" sortable={false} label="Subcategory">
          <TextField source={ESubCategory.EnName} />
        </ReferenceField>
        <DateField source={EBrand.CreatedAt} sortable={false} />
        <DateField source={EBrand.UpdatedAt} sortable={false} />

        <WithRecord
          render={(record) => (
            <Button
              onClick={handleRedirect(record)}
              endIcon={<LocalMallOutlinedIcon />}
              variant="outlined"
              disabled={!record[EBrand.ProductsCount]}
            >
              <h6
                style={{
                  margin: '0',
                  fontSize: '12px',
                  borderRight: `1px solid ${colors.cyanosed[400]}`,
                  paddingRight: '8px',
                }}
              >
                Brand Products
              </h6>
            </Button>
          )}
        />
      </Datagrid>
    </List>
  )
}
