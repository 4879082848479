import { axiosAuthInstance } from '../axios'

export const apiPasswordChange = async (credentials: { session: string; username: string; newPassword: string }) => {
  try {
    const { data } = await axiosAuthInstance.post('/dashboard/auth/password-change', {
      ...credentials,
    })

    return data
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
